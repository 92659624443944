import React from 'react'
import styles from './styles.module.scss'
import { HeroVideo } from '../components/HeroVideo'
import { HeroTitle } from '../components/HeroTitle'

export const Intro = () => {
	return (
		<div className={styles.intro}>
			<HeroVideo top={'-550'} />

			<HeroTitle>
				<span>
					<strong>AVA</strong> AI Trainer
				</span>

				<span>Analytics</span>
			</HeroTitle>
		</div>
	)
}
