import React, { useState } from 'react'
import { RootDesc } from '@/components/ui/descriptions/RootDesc'

import redefining from '@/assets/images/home/suit/redefining.png'
import guide from '@/assets/images/home/suit/guide.png'
import managers from '@/assets/images/home/suit/managers.png'
import hcp from '@/assets/images/home/suit/hcp.png'
import rotateFrontImage from '@/assets/images/home/suit/rotate-front.png'
import rotateBackImage from '@/assets/images/home/suit/rotate-back.png'

import styles from './styles.module.scss'
import { useSelector } from 'react-redux'

const RotateCard = props => {
	const { title, subtitle, desc, image } = props
	const [rotate, setRotate] = useState(false)
	const { isMobile } = useSelector(state => state.settings)

	const handleClickCard = () => {
		setRotate(!rotate)
	}

	return (
		<li className={styles.rotate_card_wrap}>
			<div
				onClick={handleClickCard}
				style={{
					transform: rotate ? 'rotateY(-180deg)' : 'rotateY(0deg)',
				}}
				className={styles.rotate_card}
			>
				<div className={styles.rotate_card_front}>
					<h3>{title}</h3>

					<p>{subtitle}</p>

					<div className={styles.rotate_card_image}>
						<img src={image} alt='image' />
					</div>

					<div className={styles.rotate_card_icon}>
						<img
							src={!isMobile ? rotateFrontImage : rotateBackImage}
							alt='rotate-front'
						/>
					</div>
				</div>

				<div className={styles.rotate_card_back}>
					<RootDesc>{desc}</RootDesc>

					{!isMobile && (
						<div className={styles.rotate_card_icon}>
							<img src={rotateBackImage} alt='rotate-back' />
						</div>
					)}
				</div>
			</div>
		</li>
	)
}

export const RotateCards = () => {
	const rotateCards = [
		{
			id: 0,
			title: <>AVA AI Trainer</>,
			subtitle: (
				<>
					Redefining Sales <br /> Training
				</>
			),
			desc: (
				<>
					<span>
						The flagship simulation trainer for sales reps, AVA AI Trainer is
						the gateway to immersive, dynamic learning experiences.
					</span>
					<span>
						<b>Practice in Safe Simulations:</b> Sales reps sharpen their skills
						in a risk-free environment with virtual HCPs, tailored to replicate
						real-world scenarios.
					</span>
					<span>
						<b>Bridging Sales and Marketing:</b> Marketing teams along with
						Sales Managers and Trainers can upload content or updates (like
						revised product messaging) into AVA’s training library. Sales reps
						quickly internalise these changes through fast-track, interactive
						content using video avatars combined with interactive questions to
						provide an engaging and efficient learning experience.
					</span>
					<span>
						<b>Edutainment Excellence:</b> Video avatars, quests, and mini games
						turn routine training into an engaging experience, boosting
						confidence and retention.
					</span>
				</>
			),
			image: redefining,
		},
		{
			id: 1,
			title: <>AVA AI Mentor</>,
			subtitle: <>The Always-On Guide</>,
			desc: (
				<>
					<span>
						AVA AI Mentor acts like a virtual assistant for sales reps, offering
						proactive, personalised support 24/7.
					</span>
					<ul>
						<li>
							<b>Proactive Engagement:</b> AVA Mentor checks in with reps,
							offering tailored learning content based on their recent
							performance and career stage.
						</li>
						<li>
							<b>Answering Questions Instantly:</b> From product details to
							compliance queries, AVA Mentor saves time by automating responses,
							so trainers can focus on strategic initiatives.
						</li>
						<li>
							<b>Boosting Productivity:</b> Reps spend just 28% of their week
							actually selling, with the majority of their time consumed by
							other tasks like deal management and data entry. AVA Mentor
							streamlines workflows, empowering reps to focus on what matters -
							closing deals and driving results.
						</li>
					</ul>
				</>
			),
			image: guide,
		},
		{
			id: 2,
			title: <>AVA RepCoPilot</>,
			subtitle: (
				<>
					Empowering Managers <br /> with Insights
				</>
			),
			desc: (
				<>
					<span>
						AVA RepCoPilot provides detailed performance analysis on
						face-to-face or remote calls.
					</span>
					<ul>
						<li>
							<b>Call Analytics:</b> Evaluates rep interactions with HCPs,
							highlighting strengths, weaknesses, and emotional triggers.
						</li>
						<li>
							<b>Actionable Feedback:</b> Delivers customised strategies for
							improvement, helping reps refine their approach.
						</li>
						<li>
							<b>Performance Reports:</b> Offers detailed insights to managers,
							enabling targeted coaching that’s rooted in data.
						</li>
					</ul>
					<span>
						With AVA RepCoPilot, managers become more than supervisors - they’re
						mentors armed with actionable insights.
					</span>
				</>
			),
			image: managers,
		},
		{
			id: 3,
			title: <>CLM Suite</>,
			subtitle: (
				<>
					Elevating HCP <br /> Engagement <br /> with Dynamic <br />
					Presentations
				</>
			),
			desc: (
				<>
					<span>
						Our CLM Slides are crafted to captivate HCP attention by delivering
						customised story flows, dynamic visuals, and tailored medical
						content. Enhancing the effectiveness of each call and interaction.
					</span>
					<ul>
						<li>
							<b>Personalisation:</b> Customised content ensures that every
							presentation speaks directly to the needs and interests of the
							HCP, driving deeper engagement.
						</li>
						<li>
							<b>Follow-Up:</b> Previous key discussion points seamlessly
							integrate from the CRM into future presentations, ensuring
							continuity and reinforcing past interactions.
						</li>
						<li>
							<b>Content Animations:</b> Attention-grabbing animations highlight
							the most important details, ensuring that the HCP remains focused
							on critical messages.
						</li>
						<li>
							<b>Visual Appeal:</b> Advanced design elements make each slide
							visually compelling, boosting the overall impact of your
							presentation and leaving a lasting impression.
						</li>
						<li>
							<b>Surveys:</b> Gather real-time insights from HCPs to better
							understand their needs and tailor future interactions accordingly.
						</li>
					</ul>
				</>
			),
			image: hcp,
		},
	]

	return (
		<ul className={styles.rotate_cards}>
			{rotateCards &&
				rotateCards.length > 0 &&
				rotateCards.map(card => (
					<React.Fragment key={card.id}>
						<RotateCard
							title={card.title}
							subtitle={card.subtitle}
							desc={card.desc}
							image={card.image}
						/>
					</React.Fragment>
				))}
		</ul>
	)
}
