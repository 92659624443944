import React, { useEffect, useState } from 'react'
import ReactDOM from 'react-dom'
import { Swiper, SwiperSlide } from 'swiper/react'
import { EffectCoverflow, Pagination, Navigation } from 'swiper/modules'
import { useSelector } from 'react-redux'

import { BorderArticle } from '@/components/ui/general/BorderArticle'
import { Overlay } from '@/components/layouts/Overlay'

import closeImg from '@/assets/images/popups/close-white.svg'
import blob from '@/assets/images/home/peach-blob.png'
import styles from './styles.module.scss'
import 'swiper/css'
import 'swiper/css/pagination'
import 'swiper/css/navigation'
import './slider.scss'

export const PreviewSlider = ({ cards }) => {
	const [currentSlide, setCurrentSlide] = useState(null)
	const [isPopupOpen, setIsPopupOpen] = useState(false)
	const { isMobile } = useSelector(state => state.settings)

	const handleClickSlide = card => {
		setCurrentSlide(card)
		console.log(currentSlide)
	}

	const handleClickClose = () => {
		setCurrentSlide(null)
	}

	useEffect(() => {
		setIsPopupOpen(!!currentSlide)

		return () => {
			setIsPopupOpen(false)
		}
	}, [currentSlide])

	return (
		<>
			<div className={'preview_slider'}>
				<img className={styles.blob} src={blob} alt='blob' />

				{!isMobile ? (
					<Swiper
						key={isMobile ? 'mobile' : 'desktop'}
						effect={'coverflow'}
						grabCursor={true}
						centeredSlides={true}
						initialSlide={1}
						slidesPerView={'auto'}
						coverflowEffect={{
							rotate: 0,
							stretch: 0,
							depth: 100,
							modifier: 2,
							slideShadows: false,
						}}
						pagination={{ clickable: true }}
						modules={[EffectCoverflow, Pagination]}
						className='mySwiper'
					>
						{cards &&
							cards.length > 0 &&
							cards.map((card, index) => (
								<SwiperSlide key={index}>
									<SwiperSlide key={index}>
										<div onClick={() => handleClickSlide(card)}>
											<BorderArticle>{card.block}</BorderArticle>
										</div>
									</SwiperSlide>
								</SwiperSlide>
							))}
					</Swiper>
				) : (
					<Swiper
						key={isMobile ? 'mobile' : 'desktop'}
						slidesPerView={1}
						initialSlide={1}
						spaceBetween={20}
						pagination={{ clickable: true }}
						modules={[Pagination]}
						className='mySwiper'
					>
						{cards &&
							cards.length > 0 &&
							cards.map((card, index) => (
								<SwiperSlide key={index}>
									<SwiperSlide key={index}>
										<div onClick={() => handleClickSlide(card)}>
											<BorderArticle>{card.block}</BorderArticle>
										</div>
									</SwiperSlide>
								</SwiperSlide>
							))}
					</Swiper>
				)}
			</div>

			{isPopupOpen &&
				ReactDOM.createPortal(
					<Overlay color={'rgba(143, 135, 158, 0.80)'}>
						<div className={'slider_popup'}>
							<div onClick={handleClickClose} className={'slider_popup_close'}>
								<img src={closeImg} alt='close' />
							</div>

							{currentSlide && (
								<Swiper
									key={isMobile ? 'mobile' : 'desktop'}
									spaceBetween={!isMobile ? 0 : 20}
									slidesPerView={1}
									initialSlide={cards.findIndex(
										card => card.id === currentSlide.id
									)}
									navigation={true}
									modules={[Navigation]}
									className='mySwiper'
								>
									{cards &&
										cards.length > 0 &&
										cards.map((card, index) => (
											<SwiperSlide key={index}>{card.block}</SwiperSlide>
										))}
								</Swiper>
							)}
						</div>
					</Overlay>,
					document.querySelector('body')
				)}
		</>
	)
}
