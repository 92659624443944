import React from 'react'
import styles from './styles.module.scss'
import chart_40 from '@/assets/images/home/features/40-percent.png'
import chart_72 from '@/assets/images/home/features/72-percent.png'
import chart_83 from '@/assets/images/home/features/83-percent.png'
import { BorderArticle } from '@/components/ui/general/BorderArticle'

export const Complete = () => {
	const charts = [
		{
			id: 0,
			image: chart_40,
			desc: (
				<>
					Improvement <br />
					in&nbsp;information retention
				</>
			),
		},
		{
			id: 1,
			image: chart_72,
			desc: (
				<>
					of&nbsp;employees believe that <br />
					gamification motivates them <br />
					to&nbsp;complete harder tasks
				</>
			),
		},
		{
			id: 2,
			image: chart_83,
			desc: (
				<>
					of&nbsp;employees who completed <br />
					gamified training are more <br />
					motivated at&nbsp;work
				</>
			),
		},
	]

	return (
		<BorderArticle>
			<div className={styles.complete_wrapper}>
				<div className={styles.complete_wrap}>
					<div className={styles.complete_desc}>
						<h4>
							Complete cycle learning <br /> experience:
						</h4>

						<p>
							From concept to&nbsp;creative execution, Vivanti delivers
							a&nbsp;dynamic <nobr>AI-powered</nobr> learning platform and
							engaging content.
						</p>
					</div>

					<div className={styles.complete_charts}>
						<h6>Impact of immersive formats:</h6>

						<ul>
							{charts &&
								charts.length > 0 &&
								charts.map(chart => (
									<li key={chart.id}>
										<img src={chart.image} alt='chart' />
										<span>{chart.desc}</span>
									</li>
								))}
						</ul>
					</div>
				</div>

				<p className={styles.complete_bottom}>
					Our medical expertise enables the development of&nbsp;unique formats,
					even for the most complex products.
				</p>
			</div>
		</BorderArticle>
	)
}
