import { useEffect, useRef, useState } from 'react'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import StreamingAvatar, {
	AvatarQuality,
	StreamingEvents,
} from '@heygen/streaming-avatar'
import {
	clearAvatarState,
	createScenarioSession,
	setIsSessionActive,
	getQuestion,
	createProductSession,
	setProductSessionId,
	setScenarioSessionId,
	setScore,
	setServerStatus,
} from '@/redux/slices/avatarSlices/avatarSlice'
import { useTranslation } from 'react-i18next'

import { LevelPopup } from '@/popups/simulationPopups/LevelPopup'
import { AiFeedbackPopup } from '@/popups/simulationPopups/AiFeedbackPopup'
import { usePopup } from '@/components/layouts/InfoPopup/PopupContext'
import { RootIcon } from '@/components/ui/icons/RootIcon'
import { RootDesc } from '@/components/ui/descriptions/RootDesc'
import { BorderArticle } from '@/components/ui/general/BorderArticle'
import { Controls } from './Controls'
import { Spinner } from '@/components/ui/general/Spinner'
import { AvatarErrorPopup } from '@/popups/simulationPopups/AvatarErrorPopup'

import doctorImage from '@/assets/images/general/doctor.png'
import styles from './styles.module.scss'

// раскоментировать для теста видео
// import videoMp4 from '@/assets/fake_avatar.mp4'
// import videoWebm from '@/assets/fake_avatar.webm'
// import videoMov from '@/assets/fake_avatar.mov'

function AvatarImage({ time, setTime, restartTime }) {
	// закоментировать для теста видео
	const videoMp4 = '/video/fake_avatar.mp4'
	const videoWebm = '/video/fake_avatar.webm'
	const videoMov = '/video/fake_avatar.mov'

	const {
		messages,
		product_session_id,
		scenario_session_id,
		score,
		sessionState,
		questions,
		serverStatus,
		volume,
		avatarToken,
		botMessage,
		isSessionActive,
		sessionType,
		avatarId,
		error,
	} = useSelector(state => state.avatar)

	const fakeVideoRef = useRef(null)
	const videoRef = useRef(null)
	const [avatar, setAvatar] = useState(null)
	const [sessionData, setSessionData] = useState(null)
	const [avatarLoading, setAvatarLoading] = useState(false)

	const { t } = useTranslation()
	const { openPopup, closePopup } = usePopup()
	const { demo, role } = useSelector(state => state.candidate)
	const { isMobile } = useSelector(state => state.settings)
	const dispatch = useDispatch()
	const navigate = useNavigate()
	const location = useLocation()
	const level = location.state?.level
	const params = useParams()

	const initializeAvatarSession = async () => {
		if (avatarToken) {
			dispatch(setServerStatus('loading'))
			setAvatarLoading(true)

			try {
				const newAvatar = new StreamingAvatar({ token: avatarToken })
				const data = await newAvatar.createStartAvatar({
					quality: AvatarQuality.High,
					avatarName: avatarId,
					// language: 'Russian',
					voice: {
						rate: 1.5,
						emotion: 'serious',
					},
				})

				setSessionData(data)
				setAvatar(newAvatar)
				dispatch(setIsSessionActive(true))
				dispatch(setServerStatus('success'))

				if (product_session_id) {
					dispatch(getQuestion({ id: product_session_id }))
				}

				newAvatar.on(StreamingEvents.STREAM_READY, handleStreamReady)
				newAvatar.on(
					StreamingEvents.STREAM_DISCONNECTED,
					handleStreamDisconnected
				)
			} catch (e) {
				dispatch(setServerStatus('error'))
				openPopup(
					<AvatarErrorPopup
						onClickTryAgain={handleTryAgain}
						onClickExit={handleQuit}
					/>,
					{ closeButton: false }
				)
			}

			setAvatarLoading(false)
		}
	}

	const handleStreamReady = event => {
		if (event.detail && videoRef.current) {
			videoRef.current.srcObject = event.detail
			videoRef.current.onloadedmetadata = () => {
				videoRef.current.play().catch(console.error)
			}
		} else {
			openPopup(
				<AvatarErrorPopup
					onClickTryAgain={handleTryAgain}
					onClickExit={handleQuit}
				/>,
				{ closeButton: false }
			)
		}
	}

	const handleStreamDisconnected = () => {
		if (videoRef.current) {
			videoRef.current.srcObject = null
		}
		dispatch(setIsSessionActive(false))
	}

	const terminateAvatarSession = async () => {
		if (!avatar || !sessionData) return

		await avatar.stopAvatar()

		if (videoRef.current) {
			videoRef.current.srcObject = null
		}

		setAvatar(null)
		setSessionData(null)
	}

	const handleSpeak = async msg => {
		await avatar.speak({ text: msg, task_type: 'repeat' })
	}

	const handleCloseLevelPopup = async () => {
		if (demo && role === 'Representative') {
			handleTryAgain()
		} else {
			if (product_session_id) {
				await dispatch(getQuestion({ id: product_session_id }))
			}
		}

		closePopup()
	}

	const handleTryAgain = async () => {
		await dispatch(clearAvatarState())
		await dispatch(setScore(null))

		if (sessionType === 'product') {
			await dispatch(
				createProductSession({
					level: params.level,
					product_id: params.scenario_id,
				})
			)

			restartTime(new Date(Date.now() + level.remainingProductTime), false)

			if (demo && role === 'Representative') return

			await dispatch(getQuestion({ id: product_session_id }))
		} else if (sessionType === 'scenario') {
			await dispatch(
				createScenarioSession({
					level: params.level,
					scenario_id: params.scenario_id,
				})
			)

			restartTime(new Date(Date.now() + level.remainingScenarioTime), false)
		}

		await dispatch(setIsSessionActive(true))
		closePopup()
	}

	const handleQuit = async () => {
		if (avatar) {
			await terminateAvatarSession()
		}

		await dispatch(setScore(null))
		await dispatch(clearAvatarState())
		await dispatch(setProductSessionId(null))
		await dispatch(setScenarioSessionId(null))
		await dispatch(setIsSessionActive(false))

		closePopup()

		if (demo && role === 'Representative') return

		navigate(-1)
	}

	const handleClickQuitBtn = async () => {
		if (demo && role === 'Representative') {
			if (messages.length > 1) {
				restartTime(Date.now(), false)
			} else {
				dispatch(setIsSessionActive(false))
			}

			if (avatar) {
				terminateAvatarSession()
				dispatch(setIsSessionActive(false))
			}
		} else {
			if (messages.length > 1) {
				restartTime(Date.now(), false)
			} else {
				navigate(-1)
				dispatch(setProductSessionId(null))
				dispatch(setScenarioSessionId(null))
			}
		}
	}

	useEffect(() => {
		if (
			(scenario_session_id &&
				(score === 0 ||
					sessionState === 'ended' ||
					questions === 0 ||
					time === 0)) ||
			(product_session_id && time === 0)
		) {
			if (avatar) {
				terminateAvatarSession()
			}

			openPopup(<AiFeedbackPopup again={handleTryAgain} quit={handleQuit} />, {
				closeButton: false,
			})
		}
	}, [
		time,
		questions,
		score,
		sessionState,
		scenario_session_id,
		product_session_id,
	])

	useEffect(() => {
		if (
			demo &&
			role === 'Representative' &&
			product_session_id &&
			messages.length === 0
		) {
			dispatch(getQuestion({ id: product_session_id }))
		}
	}, [product_session_id])

	useEffect(() => {
		if (demo && role === 'Representative' && avatar && botMessage !== '') {
			handleSpeak(botMessage)
		}
	}, [botMessage])

	useEffect(() => {
		if (demo && role === 'Representative' && videoRef.current) {
			videoRef.current.volume = volume
		}
	}, [volume])

	useEffect(() => {
		if (demo && role === 'Representative' && avatarToken !== '') {
			initializeAvatarSession()
		}
	}, [avatarToken])

	useEffect(() => {
		if (demo && role === 'Representative') {
			// Ничего не делаем
		} else {
			dispatch(setIsSessionActive(true))
			openPopup(<LevelPopup close={handleCloseLevelPopup} item={level} />, {
				closeButton: false,
			})
		}

		return () => {
			if (demo && role === 'Representative') {
				// Ничего не делаем
			} else {
				closePopup()
				dispatch(clearAvatarState())
			}
		}
	}, [])

	// useEffect(() => {
	// 	return () => {
	// 		if (!demo && avatarToken === '') {
	// 			closePopup()
	// 			dispatch(clearAvatarState())
	// 			navigate('/simulations')
	// 		}
	// 	}
	// }, [])

	return demo && role === 'Representative' ? (
		<>
			{isSessionActive ? (
				<>
					{error === '' ? (
						<>
							<div className={styles.avatarWrapper}>
								<div className={styles.avatarPlayer}>
									{avatarLoading ? (
										<Spinner width={80} height={80} />
									) : (
										<video
											style={{ pointerEvents: 'none' }}
											id='avatarVideo'
											ref={videoRef}
											autoPlay
											playsInline
										/>
									)}
								</div>
							</div>

							<Controls />
						</>
					) : (
						<div className={styles.doctorVideo}>
							{videoMp4 && videoWebm && videoMov && (
								<video
									ref={fakeVideoRef}
									autoPlay={true}
									loop={true}
									muted={true}
									poster={doctorImage}
								>
									<source src={videoMov} type='video/quicktime' />
									<source src={videoWebm} type='video/webm' />
									<source src={videoMp4} type='video/mp4' />
								</video>
							)}
						</div>
						// <img
						// 	className={styles.doctorImage}
						// 	src={doctorImage}
						// 	alt='doctor'
						// />
					)}

					{!avatarLoading && (
						<div onClick={handleClickQuitBtn} className={styles.quitBtn}>
							<i>
								<RootIcon width={32} height={32} id={'quit'} />
							</i>

							{!isMobile && (
								<RootDesc>
									<span>{t('button.finish_chat')}</span>
								</RootDesc>
							)}
						</div>
					)}
				</>
			) : (
				<>
					<div className={styles.doctorVideo}>
						{videoMp4 && videoWebm && videoMov && (
							<video
								ref={fakeVideoRef}
								autoPlay={true}
								loop={true}
								muted={true}
								poster={doctorImage}
							>
								<source src={videoMov} type='video/quicktime' />
								<source src={videoWebm} type='video/webm' />
								<source src={videoMp4} type='video/mp4' />
							</video>
						)}
					</div>

					{/* <img
						className={styles.doctorImage}
						src={doctorImage}
						alt='doctor'
					/> */}

					<div className={styles.levelPopup}>
						<BorderArticle>
							<LevelPopup close={handleCloseLevelPopup} item={level} />
						</BorderArticle>
					</div>
				</>
			)}
		</>
	) : (
		<>
			<img className={styles.doctorImage} src={doctorImage} alt='doctor' />

			<div onClick={handleClickQuitBtn} className={styles.quitBtn}>
				<i>
					<RootIcon width={32} height={32} id={'quit'} />
				</i>

				{!isMobile && (
					<RootDesc>
						<span>{t('button.exit')}</span>
					</RootDesc>
				)}
			</div>
		</>
	)
}

export default AvatarImage
