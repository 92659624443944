import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import {
	setPhone,
	setError,
	setName,
	setEmail,
	setMessage,
	setAgree,
	clearRequestForm,
	presentationRequest,
} from '@/redux/slices/requestSlices/presentationSlice'
import PhoneInput from 'react-phone-input-2'
import ru from 'react-phone-input-2/lang/ru.json'
import { RootTextarea } from '@/components/ui/inputs/RootTextarea'
import { H3 } from '@/components/ui/titles/H3'
import { RootInput } from '@/components/ui/inputs/RootInput'
import { RootButton } from '@/components/ui/buttons/RootButton'
import { RootCheckbox } from '@/components/ui/inputs/RootCheckbox'
import { usePopup } from '@/components/layouts/InfoPopup/PopupContext'
import { RootDesc } from '@/components/ui/descriptions/RootDesc'
import { Spinner } from '@/components//ui/general/Spinner'
import { FormError } from '@/components/ui/general/FormError'
import styles from './styles.module.scss'
import 'react-phone-input-2/lib/style.css'
import { Trans, useTranslation } from 'react-i18next'
import { SuccessSendPopup } from '../../attentionPopups/SuccessSendPopup'

export const PresentationPopup = () => {
	const { language } = useSelector(state => state.settings)
	const {
		name,
		phone,
		email,
		message,
		agree,
		error,
		statusCode,
		serverStatus,
	} = useSelector(state => state.message)
	const { t } = useTranslation()
	const dispatch = useDispatch()
	const { openPopup, closePopup } = usePopup()

	const switchLanguage = lang => {
		switch (lang) {
			case 'ru':
				return ru
			case 'en':
				return ''
			default:
				return ''
		}
	}

	const getBrowserCountry = () => {
		const language = navigator.language || navigator.userLanguage
		const country = language.split('-')[1] || 'US'

		return country.toLowerCase()
	}

	const handleSubmit = async e => {
		e.preventDefault()
		dispatch(setError(''))

		if (!name || !phone || !email || !agree) {
			dispatch(setError(t('server_error.fields')))
			return
		}

		const resultAction = await dispatch(
			presentationRequest({ name, phone, email, message })
		)

		if (presentationRequest.fulfilled.match(resultAction)) {
			dispatch(clearRequestForm())
		} else {
			dispatch(setError(t('server_error.submit_data')))
		}
	}

	useEffect(() => {
		dispatch(setError(''))
	}, [name, phone, email, message, agree])

	useEffect(() => {
		return () => {
			dispatch(clearRequestForm())
		}
	}, [])

	useEffect(() => {
		if (statusCode && statusCode === 200) {
			openPopup(<SuccessSendPopup />)
		}
	}, [statusCode])

	return (
		<div className={styles.call}>
			<H3>{t('popup.presentation.title')}</H3>

			<form onSubmit={handleSubmit} className={styles.callForm}>
				<div className={styles.callInput}>
					<RootInput
						width={376}
						label={t('input.name')}
						type={'text'}
						value={name}
						required={true}
						onChange={e => dispatch(setName(e.target.value))}
						disabled={serverStatus === 'loading' && true}
					/>
				</div>

				<div className={styles.callInputPhone}>
					<RootDesc>
						<b>{t('input.phone')}</b>
					</RootDesc>

					<PhoneInput
						localization={switchLanguage(language)}
						searchPlaceholder={t('input.phone_search')}
						disableSearchIcon={true}
						enableSearch={true}
						country={getBrowserCountry()}
						value={phone}
						onChange={number => dispatch(setPhone(number))}
						inputProps={{
							name: 'phone',
							required: true,
						}}
						disabled={serverStatus === 'loading' && true}
					/>
				</div>

				<div className={styles.callInput}>
					<RootInput
						width={376}
						label={'Email'}
						type={'email'}
						value={email}
						required={true}
						onChange={e => dispatch(setEmail(e.target.value))}
						disabled={serverStatus === 'loading' && true}
					/>
				</div>

				<div className={styles.callInput}>
					<RootTextarea
						placeholder={t('input.optional')}
						label={t('input.message')}
						type={'text'}
						value={message}
						required={false}
						onChange={e => dispatch(setMessage(e.target.value))}
						disabled={serverStatus === 'loading' && true}
					/>
				</div>

				<div className={styles.callCheckbox}>
					<RootCheckbox
						fontSize={14}
						checked={agree}
						onChange={e => dispatch(setAgree(e.target.checked))}
						label={
							<>
								<Trans i18nKey='input.privacy_checkbox_label'>
									{t('input.privacy_checkbox_label')}
								</Trans>{' '}
								<Link onClick={() => closePopup()} to={'/privacy'}>
									{t('input.privacy_checkbox_link')}
								</Link>
							</>
						}
					/>
				</div>

				<RootButton
					txt={t('button.submit')}
					type={'submit'}
					disabled={
						!name || !phone || !email || !agree || serverStatus === 'loading'
					}
				/>

				{error !== '' && <FormError error={error} bottom={105} />}
				{serverStatus === 'loading' && <Spinner width={50} height={50} />}
			</form>
		</div>
	)
}
