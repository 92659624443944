import React from 'react'

import { BorderArticle } from '@/components/ui/general/BorderArticle'
import { HeroVideo } from '../components/HeroVideo'

import styles from './styles.module.scss'
import { HeroTitle } from '../components/HeroTitle'

const SalesItem = ({ count, title }) => {
	return (
		<BorderArticle>
			<li>
				<b>{count}</b>
				<span>{title}</span>
			</li>
		</BorderArticle>
	)
}

export const Intro = () => {
	const sales = [
		{
			count: '1',
			title: (
				<>
					Consistency <br />
					in&nbsp;product knowledge <br />
					and sales skills
				</>
			),
		},
		{
			count: '2',
			title: (
				<>
					Boost in&nbsp;sales <br />
					performance positively <br />
					impacting revenues
				</>
			),
		},
		{
			count: '3',
			title: (
				<>
					Realistic <nobr>AI-powered</nobr> <br />
					<nobr>role-play</nobr> for HCP <br />
					dialog excellence
				</>
			),
		},
		{
			count: '4',
			title: (
				<>
					Actionable feedback <br />
					for both reps and <br />
					trainers/managers
				</>
			),
		},
	]

	return (
		<div className={styles.intro}>
			<HeroVideo hero={true} />

			<HeroTitle>
				<span>
					Meet <strong>AVA AI Trainer:</strong>
				</span>

				<span>GenAI-Powered Multilingual Training Simulator</span>
			</HeroTitle>

			<div className={styles.sales}>
				<h3>AVA Pharma Sales Trainings Facilitate:</h3>

				<ul>
					{sales &&
						sales.length > 0 &&
						sales.map(sale => (
							<React.Fragment key={sale.count}>
								<SalesItem count={sale.count} title={sale.title} />
							</React.Fragment>
						))}
				</ul>

				<p>
					Drive sales success by&nbsp;enhancing engagement and ensuring
					alignment with company strategy.
				</p>
			</div>
		</div>
	)
}
