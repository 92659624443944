import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { useLocation, useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

import { Timer } from './Timer'
import { useChatTime } from '@/hooks/ChatTime'
import { Header } from '@/components/layouts/Header'
import { Controls } from './Controls'
import { Chat } from './Chat'
import { RootButton } from '@/components/ui/buttons/RootButton'
import AvatarComponent from './AvatarComponent'
import AvatarImage from './AvatarImage'
import { H3 } from '@/components/ui/titles/H3'
import { RootIcon } from '@/components/ui/icons/RootIcon'

import styles from './styles.module.scss'

export const ChatGame = () => {
	const [showAvatar, setShowAvatar] = useState(false)
	const { demo, role } = useSelector(state => state.candidate)
	const { isMobile } = useSelector(state => state.settings)
	const {
		error,
		scenario_session_id,
		product_session_id,
		avatarToken,
		isSessionActive,
		sessionType,
	} = useSelector(state => state.avatar)
	const { t } = useTranslation()
	const location = useLocation()
	const navigate = useNavigate()

	const remainingTime = scenario_session_id
		? location?.state?.level?.remainingScenarioTime
		: location?.state?.level?.remainingProductTime

	const [chatTime, setChatTimeState, restart] = useChatTime(remainingTime)

	useEffect(() => {
		if (demo && role === 'Representative') {
			if (!sessionType) {
				navigate('/simulations')
			} else {
				setChatTimeState(remainingTime)
			}
		} else {
			if (!scenario_session_id && !product_session_id && avatarToken === '') {
				navigate('/simulations')
			} else if (remainingTime > 0) {
				setChatTimeState(remainingTime)
			}
		}
	}, [scenario_session_id, product_session_id, remainingTime])

	useEffect(() => {
		document.body.style.background = '#b7d7e7'

		return () => {
			document.body.style.background =
				'linear-gradient( 90deg, rgba(144, 178, 234, 0.4) 0%,rgba(245, 129, 213, 0.4)100%),#fff'
		}
	}, [])

	return (
		<div className={styles.gameWrapper}>
			{demo && role === 'Representative' && (
				<div className={styles.gameDemoLink}>
					<a target='_blank' href='https://ava-request.vivanti.eu'>
						<H3>
							<span>Book a Demo!</span>
						</H3>
					</a>
				</div>
			)}

			<Header />

			<div className='container-big phone:p-0 h-[100%] flex'>
				<div className={styles.game}>
					<div className={styles.doctor}>
						{window.location.host.includes('test') ||
						window.location.host.includes('localhost') ? (
							<>
								{demo && role === 'Representative' ? (
									<></>
								) : (
									<div className={styles.btn}>
										<RootButton
											onClick={() => setShowAvatar(!showAvatar)}
											txt={
												isMobile ? (
													<RootIcon width={24} height={24} id={'show-pass'} />
												) : (
													t('button.show_avatar')
												)
											}
										/>
									</div>
								)}

								{showAvatar ? (
									error === '' ? (
										<>
											<AvatarComponent
												restartTime={restart}
												time={chatTime}
												setTime={setChatTimeState}
											/>

											<Controls />
										</>
									) : (
										<AvatarImage
											restartTime={restart}
											time={chatTime}
											setTime={setChatTimeState}
										/>
									)
								) : (
									<AvatarImage
										restartTime={restart}
										time={chatTime}
										setTime={setChatTimeState}
									/>
								)}
							</>
						) : error === '' ? (
							<>
								<AvatarComponent
									restartTime={restart}
									time={chatTime}
									setTime={setChatTimeState}
								/>

								<Controls />
							</>
						) : (
							<AvatarImage
								restartTime={restart}
								time={chatTime}
								setTime={setChatTimeState}
							/>
						)}
					</div>

					{demo && role === 'Representative' ? (
						<>
							{isSessionActive && (
								<>
									<Timer time={chatTime} />

									<div className={styles.gameChatWrapper}>
										<Chat time={chatTime} />
									</div>
								</>
							)}
						</>
					) : (
						<>
							<Timer time={chatTime} />

							<div className={styles.gameChatWrapper}>
								<Chat time={chatTime} />
							</div>
						</>
					)}
				</div>
			</div>
		</div>
	)
}
