import React from 'react'
import { Helmet } from 'react-helmet'
import { Intro } from './Intro'
import styles from './styles.module.scss'
import { Try } from '../components/Try'
import { Accordeon } from '../components/Accordeon'
import { PreviewSlider } from '../components/PreviewSlider'
import { Complete } from './Complete'
import { usePopup } from '@/components/layouts/InfoPopup/PopupContext'
import { PresentationPopup } from '@/popups/requestPopups/PresentationPopup'

import chatScreen from '@/assets/images/home/features/chat-screen.png'
import simulationsScreen from '@/assets/images/home/features/simulations-screen.png'
import profileScreen from '@/assets/images/home/features/profile-screen.png'

export const Features = () => {
	const accordeonItems = [
		{
			id: 0,
			head: <>Call and product simulation with the HCP avatar</>,
			body: (
				<>
					<span>
						Reps gain access to&nbsp;tailored simulations of&nbsp;their product
						line, as well as&nbsp;an&nbsp;indication of&nbsp;the priority for
						completing courses. Each simulation includes a&nbsp;detailed task
						description, the option to select a&nbsp;complexity level, and the
						ability to&nbsp;choose the character they will interact with. This
						flexibility allows representatives to align the simulations with
						their <nobr>real-world</nobr> practice.
					</span>

					<ul>
						<li>
							<b>Product Bootcamp: </b> Assesses product knowledge, ensuring
							mastery of&nbsp;recommendations, key messages, and product
							insights.
						</li>
						<li>
							<b>Call Simulation:</b> Develops sales skills by&nbsp;focusing on
							building trust with various customer types and confidently
							presenting marketing strategy from one to&nbsp;several products
							in&nbsp;the line.
						</li>
					</ul>

					<span>
						Three complexity levels progress from basic product knowledge for
						loyal customers to&nbsp;complex interactions with
						<nobr>non-loyal</nobr> customers who demand detailed research and
						evidence, all within <nobr>time-constrained</nobr>
						visits.
					</span>

					<span>
						Managers and trainers can have access to&nbsp;create simulations and
						assign them to&nbsp;their team.
					</span>
				</>
			),
			check_1: true,
			check_2: true,
		},
		{
			id: 1,
			head: 'Performance monitoring',
			body: (
				<>
					<span>
						AVA AI&nbsp;Trainer provides feedback after each Reps session, which
						include
					</span>

					<ul>
						<li>
							Overall call assessment; key strengths and weaknesses of&nbsp;the
							interaction.
						</li>
						<li>
							Product mentions; product knowledge and strategy consistency.
						</li>
						<li>
							Key message delivery: evaluating whether all key messages were
							conveyed and how effectively they were communicated to&nbsp;the
							HCP.
						</li>
						<li>
							Dialogue analysis; behaviour analysis and sales techniques
							assessment.
						</li>
						<li>Recommendations for improvement.</li>
					</ul>

					<span>
						Managers and trainers can monitor their team&rsquo;s progress
						through a summarised feedback form on&nbsp;the dashboard, with the
						option to&nbsp;access detailed reports if&nbsp;needed. This allows
						for the assignment of&nbsp;gap exercises and simulations, creating
						personalised learning paths to enhance overall performance.
					</span>
				</>
			),
			check_1: true,
			check_2: true,
		},
		{
			id: 2,
			head: 'Knowledge validation and certification',
			body: (
				<span>
					In&nbsp;the standard package, managers can define the required level
					of knowledge and skills needed to&nbsp;complete a&nbsp;simulation
					successfully. Employee certification is&nbsp;then based on&nbsp;the
					percentage of&nbsp;simulations successfully completed within specified
					courses over a&nbsp;set time period. The advanced version allows for
					the integration of&nbsp;additional customised metrics to&nbsp;meet
					specific certification needs.
				</span>
			),
			check_1: true,
			check_2: true,
		},
		{
			id: 3,
			head: 'Client knowledge base integration',
			body: (
				<span>
					Already using an&nbsp;LMS? Enhance your training ecosystem
					by&nbsp;integrating AVA to&nbsp;create a&nbsp;closed learning loop.
					This integration allows representatives to&nbsp;easily refresh their
					knowledge before starting simulations, ensuring a&nbsp;seamless and
					continuous learning experience.
				</span>
			),
			check_1: false,
			check_2: true,
		},
		{
			id: 4,
			head: 'Immersive training library',
			body: (
				<>
					<span>
						For clients without an&nbsp;LMS, we&nbsp;offer a&nbsp;comprehensive
						solution with our Immersive Training Library, designed to&nbsp;serve
						as&nbsp;a&nbsp;centralised hub for all your training resources. This
						platform allows pharmaceutical companies to&nbsp;easily upload and
						manage materials while offering optional features like video avatar
						integration to transform static content into dynamic, interactive
						video formats for faster, more engaging learning. With
						<nobr>on-demand</nobr> content refresh capabilities, employees can
						access key training materials whenever needed.
					</span>

					<span>
						The library provides a&nbsp;streamlined and effective learning
						experience, enhanced by&nbsp;optional gamified elements like quizzes
						and mini games for increased engagement.
					</span>
				</>
			),
			check_1: false,
			check_2: true,
		},
		{
			id: 5,
			head: 'Hybrid training approach',
			body: (
				<>
					<span>
						Hybrid Training Mode seamlessly integrates AVA’s&nbsp;
						<nobr>AI-driven</nobr> digital simulations with
						<nobr>trainer-led</nobr> sessions, creating a&nbsp;balanced and
						<nobr>cost-effective</nobr> approach to&nbsp;education. Through
						a&nbsp;Continuous Learning Loop, reps build foundational skills with
						simulations, which are then expanded in&nbsp;live sessions focused
						on&nbsp;specific performance needs.
					</span>

					<span>
						By&nbsp;leveraging AVA’s&nbsp;performance analytics, trainers gain
						actionable insights to&nbsp;tailor sessions to&nbsp;individual skill
						gaps, ensuring precise, adaptable, and effective training. This
						synergy of&nbsp;<nobr>AI-powered</nobr> insights and expert coaching
						enhances trainer effectiveness, boosts sales performance, and
						improves HCP engagement with refined sales techniques.
					</span>
				</>
			),
			check_1: false,
			check_2: true,
		},
		{
			id: 6,
			head: <>Custom usage scenarios, including DE&I&nbsp;simulations</>,
			body: (
				<>
					<span>
						With AVA, you can create simulations for a&nbsp;wide range
						of&nbsp;scenarios and certifications across all departments and
						objectives:
					</span>

					<ul>
						<li>Products launch</li>
						<li>First call</li>
						<li>Follow up call</li>
						<li>Handling objections against competitors</li>
						<li>Winning with non-loyal HCP</li>
						<li>OMNI rep strategy</li>
					</ul>

					<span>
						AVA is&nbsp;designed to&nbsp;support any situation that requires the
						validation of&nbsp;knowledge or&nbsp;skills in&nbsp;communication
						interactions. Whether it&rsquo;s DE&I&nbsp;training,
						<nobr>anti-bias</nobr> courses, <nobr>eco-feedback</nobr>
						sessions, or&nbsp;simulations for reception operators, AVA offers
						versatile solutions.
					</span>
				</>
			),
			check_1: false,
			check_2: true,
		},
		{
			id: 7,
			head: 'Call chains simulations, comprehensive avatar memory',
			body: (
				<>
					<span>
						The avatar adapts to&nbsp;the representative&rsquo;s style and
						remembers previous interactions. If&nbsp;the representative was
						rude, failed to follow the rules of&nbsp;a&nbsp;sales visit,
						or&nbsp;couldn’t&nbsp;answer a&nbsp;question, the avatar will
						address these mistakes (or&nbsp;successes) during the next
						simulation, adjusting its behaviour accordingly to&nbsp;provide a
						more personalised and impactful learning experience.
					</span>

					<span>
						Through a&nbsp;call chain of&nbsp;simulations, you can practice
						calls with both familiar doctors (where casual conversations about
						shared interests, like pets, come naturally) and new doctors, where
						building trust from scratch is&nbsp;essential.
					</span>
				</>
			),
			check_1: false,
			check_2: true,
		},
		{
			id: 8,
			head: (
				<>
					Customised avatar behaviour, including diverse personas, <br />
					profiles, archetypes, and cultural features
				</>
			),
			body: (
				<span>
					AVA’s&nbsp;personalised avatars and tailored behaviours bring
					a&nbsp;new level of&nbsp;realism and adaptability to&nbsp;training
					sessions. Simulate interactions with diverse HCP profiles
					by&nbsp;role, or&nbsp;personality types such as&nbsp;sceptical,
					<nobr>time-constrained</nobr>, or&nbsp;inquisitive doctors, while
					incorporating cultural nuances for regional relevance. Whether it’s
					engaging with a&nbsp;busy doctor in&nbsp;the UK&nbsp;or&nbsp;building
					rapport with a&nbsp;new,
					<nobr>non-loyal</nobr> pharmacist in&nbsp;Italy, your team will
					be&nbsp;prepared to&nbsp;navigate any challenge with confidence.
				</span>
			),
			check_1: false,
			check_2: true,
		},
		{
			id: 9,
			head: <>RAG architecture on&nbsp;your server</>,
			body: (
				<span>
					Our use of&nbsp;large language models and cloud infrastructure
					prioritises data security and the protection of&nbsp;trade secrets.
					With
					<nobr>Retrieval-Augmented</nobr> Generation (RAG) architecture,
					we&nbsp;strictly control AI’s&nbsp;access to&nbsp;customer data while
					significantly improving the accuracy and relevance of&nbsp;information
					retrieved from knowledge bases. Depending on&nbsp;the company&rsquo;s
					policies, the RAG architecture can be hosted on&nbsp;our facilities
					or&nbsp;deployed directly on&nbsp;the client’s&nbsp;servers for
					optimal compliance and flexibility.
				</span>
			),
			check_1: false,
			check_2: true,
		},
	]

	const sliderItems = [
		{
			id: 0,
			block: <img src={simulationsScreen} alt={`preview-screenshot`} />,
		},
		{
			id: 1,
			block: <img src={chatScreen} alt={`preview-screenshot`} />,
		},
		{
			id: 2,
			block: <img src={profileScreen} alt={`preview-screenshot`} />,
		},
	]

	const { openPopup } = usePopup()

	const handleOpenPopup = () => {
		openPopup(<PresentationPopup />)
	}

	return (
		<>
			<Helmet title='Cosmart AVA AI Trainer Features' />

			<div className='container-big'>
				<div className={styles.features_page}>
					<Intro />

					<div className='container-home'>
						<Accordeon
							title={
								<>
									Main Features <br />
									and Packages
								</>
							}
							tableHead={['Features', 'STANDARD*', 'ADVANCED**']}
							items={accordeonItems}
							subtitle={
								<>
									* For the standard package, you can upload your data
									on&nbsp;our servers directly. <br />
									** The final set of&nbsp;features will be&nbsp;determined
									at&nbsp;the research stage.
								</>
							}
						/>
					</div>

					<div className='container-home'>
						<Try
							onClickBtn={handleOpenPopup}
							size={35}
							title={
								<b>
									If you can’t find a&nbsp;specific feature or&nbsp;have
									an&nbsp;innovative suggestion, we’d&nbsp;love to&nbsp;hear
									from you! Reach out to&nbsp;us&nbsp;&mdash; we’re&nbsp;always
									eager to&nbsp;collaborate and improve.
								</b>
							}
						/>
					</div>

					<PreviewSlider cards={sliderItems} />

					<div className='container-home'>
						<Complete />
					</div>
				</div>
			</div>
		</>
	)
}
