import React from 'react'
import scrollArrow from '@/assets/images/home/scroll-btn.svg'
import styles from './styles.module.scss'

export const ScrollBtn = ({ click }) => {
	return (
		<div className={styles.scroll_btn_wrapper} onClick={click}>
			<div className={styles.scroll_btn}>
				<img src={scrollArrow} alt='arrow' />
			</div>
		</div>
	)
}
