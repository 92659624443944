import React, { useEffect, useRef, useState } from 'react'
import { Swiper, SwiperSlide } from 'swiper/react'
import { Pagination, Autoplay } from 'swiper/modules'
import 'swiper/css'
import 'swiper/css/pagination'
import './slider.scss'
import { RootIcon } from '@/components/ui/icons/RootIcon'
import { useSelector } from 'react-redux'

export const SimpleSlider = props => {
	const {
		cards,
		between,
		perview,
		navigation,
		pagination,
		loop,
		autoplay,
		style,
	} = props

	const swiperRef = useRef(null)
	const [currentIndex, setCurrentIndex] = useState(0)
	const { isMobile } = useSelector(state => state.settings)

	const handlePrev = () => {
		if (navigation && swiperRef.current) {
			swiperRef.current.swiper.slidePrev()
		}
	}

	const handleNext = () => {
		if (navigation && swiperRef.current) {
			swiperRef.current.swiper.slideNext()
		}
	}

	const handleSlideChange = swiper => {
		if (navigation) {
			setCurrentIndex(swiper.activeIndex)
		}
	}

	useEffect(() => {
		if (swiperRef.current) {
			swiperRef.current.swiper.update()

			setCurrentIndex(0)
		}
	}, [isMobile])

	return (
		<div className={style}>
			{!isMobile ? (
				<Swiper
					key={isMobile ? 'mobile' : 'desktop'}
					ref={swiperRef}
					loop={loop}
					spaceBetween={between}
					slidesPerView={perview}
					pagination={pagination ? { clickable: true } : false}
					modules={[Pagination, Autoplay]}
					onSlideChange={handleSlideChange}
					className='mySwiper'
					autoplay={
						autoplay
							? {
									delay: autoplay,
									disableOnInteraction: false,
							  }
							: false
					}
				>
					{cards &&
						cards.length > 0 &&
						cards.map((card, index) => (
							<SwiperSlide key={index}>{card.block}</SwiperSlide>
						))}
				</Swiper>
			) : (
				<Swiper
					ref={swiperRef}
					key={isMobile ? 'mobile' : 'desktop'}
					loop={loop}
					spaceBetween={20}
					slidesPerView={1}
					centeredSlides={true}
					pagination={{ clickable: true }}
					modules={[Pagination, Autoplay]}
					className='mySwiper'
					autoplay={
						autoplay
							? {
									delay: autoplay,
									disableOnInteraction: false,
							  }
							: false
					}
				>
					{cards &&
						cards.length > 0 &&
						cards.map((card, index) => (
							<SwiperSlide key={index}>{card.block}</SwiperSlide>
						))}
				</Swiper>
			)}

			{!isMobile && navigation && (
				<div className='slider-navigation'>
					<button
						onClick={handlePrev}
						className='button button-prev'
						disabled={currentIndex === 0}
					>
						<RootIcon width={29} heigth={34} id='nav-prev' />
					</button>

					<button
						onClick={handleNext}
						className='button button-next'
						disabled={currentIndex === cards.length - 2}
					>
						<RootIcon width={29} heigth={34} id='nav-next' />
					</button>
				</div>
			)}
		</div>
	)
}
