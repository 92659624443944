import { useEffect, useMemo } from 'react'
import { useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import {
	clearUsers,
	getUsers,
	setPage,
	setSize,
	setSort,
	setTotalPages,
} from '@/redux/slices/userSlices/usersSlice'
import moment from 'moment/min/moment-with-locales'
import { useTranslation } from 'react-i18next'

import { usePopup } from '@/components/layouts/InfoPopup/PopupContext'
import { ScreenLayout } from '@/components/layouts/ScreenLayout'
import { TableLayout } from '@/components/layouts/TableLayout'
import { ControlButton } from '@/components/ui/buttons/ControlButton'
import { RootCheckbox } from '@/components/ui/inputs/RootCheckbox'
import { UploadFileProgressPopup } from '@/popups/uploadPopups/UploadFileProgressPopup'
import { useCurrentRole } from '@/hooks/CurrentRole'
import { TableButton } from '@/components/ui/buttons/TableButton'

export const Users = () => {
	const currentRole = useCurrentRole()
	const { openPopup } = usePopup()
	const { t } = useTranslation()
	const navigate = useNavigate()
	const dispatch = useDispatch()

	const { demo } = useSelector(state => state.candidate)
	const { users, page, sort, size, totalPages, serverStatus, error } =
		useSelector(state => state.users)

	const handleOpenPopup = () => {
		openPopup(<UploadFileProgressPopup type={'csv'} />)
	}

	const handleClickDeactivateAll = () => {
		console.log('Deactivated check users!')
	}

	const handleCheckFilter = () => {
		console.log('Show inactive users!')
	}

	const goToNextPage = () => {
		if (page < totalPages) {
			dispatch(setPage(page + 1))
		} else return
	}

	const goToPreviousPage = () => {
		if (page > 1) {
			dispatch(setPage(page - 1))
		} else return
	}

	const goToPage = pageIndex => {
		dispatch(setPage(pageIndex + 1))
	}

	const handleSelectSize = option => {
		dispatch(setSize(+option))
		dispatch(setPage(1))
		dispatch(setTotalPages(Math.ceil(totalPages / size)))
	}

	const handleSelectSort = option => {
		dispatch(setSort(option))
	}

	const controls = [
		{
			id: 0,
			button: (
				<ControlButton color='var(--green)' txt={t('button.add')} link={true} />
			),
		},
		{
			id: 1,
			button: (
				<ControlButton
					color='var(--red)'
					txt={t('button.deactivate')}
					onClick={() => handleClickDeactivateAll()}
				/>
			),
		},
		{
			id: 2,
			button: (
				<ControlButton
					color='var(--violetLight)'
					txt={t('button.upload_data')}
					onClick={() => handleOpenPopup()}
				/>
			),
		},
	]

	const columns = useMemo(() => {
		const baseColumns = [
			{ Header: t('table.name'), accessor: 'name', width: 180 },
			{ Header: 'Email', accessor: 'email', width: 220 },
			{
				Header: t('table.created'),
				accessor: 'created_at',
				Cell: ({ cell: { value } }) => {
					return <>{moment(value).format('DD-MM-YYYY')}</>
				},
				width: 235,
			},
			{
				Header: t('table.role'),
				accessor: 'role',
				Cell: ({ cell: { value } }) => {
					return <>{currentRole(value)}</>
				},
				width: 305,
			},
			{ Header: t('table.product_line'), accessor: 'product_line', width: 265 },
			{
				Header: t('table.actions'),
				Cell: ({ row }) => (
					<div
						style={
							demo
								? {
										justifyContent: 'flex-end',
										marginLeft: 'auto',
										width: 'fit-content',
								  }
								: {}
						}
						className='flex gap-[8rem] phone:gap-[40rem]'
					>
						<TableButton
							icon='view'
							color='var(--orange)'
							label={t('table.button.view')}
							action={'view'}
							item={row.original}
						/>

						{!demo && (
							<>
								<TableButton
									icon='edit'
									color='var(--blueLight)'
									label={t('table.button.edit')}
									action={'edit'}
									item={row.original}
								/>

								<TableButton
									icon='deactivate'
									color='var(--red)'
									label={t('table.button.deactivate')}
									action={'deactivate'}
									item={row.original}
								/>
							</>
						)}
					</div>
				),
				width: 210,
			},
		]

		if (!demo) {
			baseColumns.unshift({
				id: 'selection',
				Header: ({ getToggleAllRowsSelectedProps }) => (
					<div>
						<RootCheckbox {...getToggleAllRowsSelectedProps()} />
					</div>
				),
				Cell: ({ row }) => (
					<div>
						<RootCheckbox {...row.getToggleRowSelectedProps()} />
					</div>
				),
				width: 50,
			})
		}

		return baseColumns
	}, [demo])

	useEffect(() => {
		if (Object.keys(sort).length !== 0) {
			dispatch(getUsers({ page, size }))
		}
	}, [page, size, sort])

	useEffect(() => {
		return () => {
			dispatch(clearUsers())
		}
	}, [location, dispatch])

	useEffect(() => {
		const firstAccessor = columns.find(column => column.accessor)

		dispatch(
			setSort({ name: firstAccessor.Header, value: firstAccessor.accessor })
		)
	}, [])

	return (
		<ScreenLayout
			controlsList={!demo && controls}
			title={t('page.users.title')}
		>
			<TableLayout
				serverStatus={serverStatus}
				serverError={error}
				nextPage={goToNextPage}
				previousPage={goToPreviousPage}
				totalPages={totalPages}
				dataSize={size}
				currentPage={page}
				toPage={goToPage}
				selectSize={handleSelectSize}
				columns={columns}
				data={users}
				dataSort={sort}
				selectSort={handleSelectSort}
				filterCheckbox={
					!demo && (
						<RootCheckbox
							onChange={() => handleCheckFilter()}
							label={t('page.users.checkbox_filter')}
						/>
					)
				}
			/>
		</ScreenLayout>
	)
}
