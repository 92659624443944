import React, { useEffect } from 'react'
import { useLocation } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'

import { Header } from '@/components/layouts/Header'
import { BorderArticle } from '@/components//ui/general/BorderArticle'
import { H3 } from '@/components/ui/titles/H3'
import { RootDesc } from '@/components/ui/descriptions/RootDesc'
import { Footer } from '@/components/layouts/Footer'
import { H2 } from '@/components/ui/titles/H2'
import { BackButton } from '@/components/ui/buttons/BackButton'
import { Logo } from '@/components/ui/general/Logo'

import styles from './styles.module.scss'

export const Privacy = () => {
	const { t } = useTranslation()
	const { auth } = useSelector(state => state.candidate)
	const location = useLocation()

	useEffect(() => {
		window.scrollTo({ top: 0, behavior: 'smooth' })
	}, [location])

	return (
		<>
			<div className='container-big'>
				<div className={styles.privacyWrapper}>
					{auth ? (
						<Header />
					) : (
						<div className={styles.privacyHead}>
							<Logo />
						</div>
					)}

					<BorderArticle>
						<div className={styles.privacy}>
							<BackButton />

							{t('page.policy.title_1') !== '' && (
								<div className={styles.privacyTitle}>
									<H3>
										<span
											dangerouslySetInnerHTML={{
												__html: t('page.policy.title_1'),
											}}
										></span>
									</H3>
								</div>
							)}

							{t('page.policy.title_2') !== '' && (
								<h1
									dangerouslySetInnerHTML={{
										__html: t('page.policy.title_2'),
									}}
								></h1>
							)}

							<RootDesc>
								<span
									dangerouslySetInnerHTML={{
										__html: t('page.policy.last_upd'),
									}}
								></span>
								<span
									dangerouslySetInnerHTML={{
										__html: t('page.policy.desc_1'),
									}}
								></span>
								<span
									dangerouslySetInnerHTML={{
										__html: t('page.policy.desc_2'),
									}}
								></span>
								<span
									dangerouslySetInnerHTML={{
										__html: t('page.policy.desc_3'),
									}}
								></span>
								<span
									dangerouslySetInnerHTML={{
										__html: t('page.policy.desc_4'),
									}}
								></span>
							</RootDesc>

							<H2>
								<span
									dangerouslySetInnerHTML={{
										__html: t('page.policy.p_1'),
									}}
								></span>
							</H2>

							<h4>
								<span
									dangerouslySetInnerHTML={{
										__html: t('page.policy.p_1_1'),
									}}
								></span>
							</h4>

							<RootDesc>
								<span
									dangerouslySetInnerHTML={{
										__html: t('page.policy.d_1_1'),
									}}
								></span>

								<ul>
									<li
										dangerouslySetInnerHTML={{
											__html: t('page.policy.l_1_1_1'),
										}}
									></li>
									<li
										dangerouslySetInnerHTML={{
											__html: t('page.policy.l_1_1_2'),
										}}
									></li>
									<li
										dangerouslySetInnerHTML={{
											__html: t('page.policy.l_1_1_3'),
										}}
									></li>
									<li
										dangerouslySetInnerHTML={{
											__html: t('page.policy.l_1_1_4'),
										}}
									></li>
									<li
										dangerouslySetInnerHTML={{
											__html: t('page.policy.l_1_1_5'),
										}}
									></li>
								</ul>
							</RootDesc>

							<h4>
								<span
									dangerouslySetInnerHTML={{
										__html: t('page.policy.p_1_2'),
									}}
								></span>
							</h4>

							<RootDesc>
								<span
									dangerouslySetInnerHTML={{
										__html: t('page.policy.d_1_2'),
									}}
								></span>

								<ul>
									<li
										dangerouslySetInnerHTML={{
											__html: t('page.policy.l_1_2_1'),
										}}
									></li>
									<li
										dangerouslySetInnerHTML={{
											__html: t('page.policy.l_1_2_2'),
										}}
									></li>
									<li
										dangerouslySetInnerHTML={{
											__html: t('page.policy.l_1_2_3'),
										}}
									></li>
									<li
										dangerouslySetInnerHTML={{
											__html: t('page.policy.l_1_2_4'),
										}}
									></li>
									<li
										dangerouslySetInnerHTML={{
											__html: t('page.policy.l_1_2_5'),
										}}
									></li>
									<li
										dangerouslySetInnerHTML={{
											__html: t('page.policy.l_1_2_6'),
										}}
									></li>
									<li
										dangerouslySetInnerHTML={{
											__html: t('page.policy.l_1_2_7'),
										}}
									></li>
									<li
										dangerouslySetInnerHTML={{
											__html: t('page.policy.l_1_2_8'),
										}}
									></li>
								</ul>
							</RootDesc>

							<h4>
								<span
									dangerouslySetInnerHTML={{
										__html: t('page.policy.p_1_3'),
									}}
								></span>
							</h4>

							<RootDesc>
								<span
									dangerouslySetInnerHTML={{
										__html: t('page.policy.d_1_3'),
									}}
								></span>
							</RootDesc>

							<H2>
								<span
									dangerouslySetInnerHTML={{
										__html: t('page.policy.p_2'),
									}}
								></span>
							</H2>

							<RootDesc>
								<span
									dangerouslySetInnerHTML={{
										__html: t('page.policy.d_2'),
									}}
								></span>

								<ul>
									<li
										dangerouslySetInnerHTML={{
											__html: t('page.policy.l_2_1'),
										}}
									></li>
									<li
										dangerouslySetInnerHTML={{
											__html: t('page.policy.l_2_2'),
										}}
									></li>
									<li
										dangerouslySetInnerHTML={{
											__html: t('page.policy.l_2_3'),
										}}
									></li>
									<li
										dangerouslySetInnerHTML={{
											__html: t('page.policy.l_2_4'),
										}}
									></li>
									<li
										dangerouslySetInnerHTML={{
											__html: t('page.policy.l_2_5'),
										}}
									></li>
									<li
										dangerouslySetInnerHTML={{
											__html: t('page.policy.l_2_6'),
										}}
									></li>
									<li
										dangerouslySetInnerHTML={{
											__html: t('page.policy.l_2_7'),
										}}
									></li>
									<li
										dangerouslySetInnerHTML={{
											__html: t('page.policy.l_2_8'),
										}}
									></li>
									<li
										dangerouslySetInnerHTML={{
											__html: t('page.policy.l_2_9'),
										}}
									></li>
								</ul>
							</RootDesc>

							<H2>
								<span
									dangerouslySetInnerHTML={{
										__html: t('page.policy.p_3'),
									}}
								></span>
							</H2>

							<RootDesc>
								<span
									dangerouslySetInnerHTML={{
										__html: t('page.policy.d_3_1'),
									}}
								></span>

								<span
									dangerouslySetInnerHTML={{
										__html: t('page.policy.d_3_2'),
									}}
								></span>

								<ul>
									<li
										dangerouslySetInnerHTML={{
											__html: t('page.policy.l_3_1'),
										}}
									></li>
									<li
										dangerouslySetInnerHTML={{
											__html: t('page.policy.l_3_2'),
										}}
									></li>
									<li
										dangerouslySetInnerHTML={{
											__html: t('page.policy.l_3_3'),
										}}
									></li>
									{t('page.policy.l_3_4') !== '' && (
										<li
											dangerouslySetInnerHTML={{
												__html: t('page.policy.l_3_4'),
											}}
										></li>
									)}
								</ul>

								{t('page.policy.d_3_3') !== '' && (
									<span
										dangerouslySetInnerHTML={{
											__html: t('page.policy.d_3_3'),
										}}
									></span>
								)}
							</RootDesc>

							<H2>
								<span
									dangerouslySetInnerHTML={{
										__html: t('page.policy.p_4'),
									}}
								></span>
							</H2>

							<RootDesc>
								<span
									dangerouslySetInnerHTML={{
										__html: t('page.policy.d_4'),
									}}
								></span>

								<ul>
									<li
										dangerouslySetInnerHTML={{
											__html: t('page.policy.l_4_1'),
										}}
									></li>
									<li
										dangerouslySetInnerHTML={{
											__html: t('page.policy.l_4_2'),
										}}
									></li>
									<li
										dangerouslySetInnerHTML={{
											__html: t('page.policy.l_4_3'),
										}}
									></li>
									<li
										dangerouslySetInnerHTML={{
											__html: t('page.policy.l_4_4'),
										}}
									></li>
								</ul>
							</RootDesc>

							<H2>
								<span
									dangerouslySetInnerHTML={{
										__html: t('page.policy.p_5'),
									}}
								></span>
							</H2>

							<RootDesc>
								<span
									dangerouslySetInnerHTML={{
										__html: t('page.policy.d_5'),
									}}
								></span>
							</RootDesc>

							<H2>
								<span
									dangerouslySetInnerHTML={{
										__html: t('page.policy.p_6'),
									}}
								></span>
							</H2>

							<RootDesc>
								<span
									dangerouslySetInnerHTML={{
										__html: t('page.policy.d_6'),
									}}
								></span>
							</RootDesc>

							<H2>
								<span
									dangerouslySetInnerHTML={{
										__html: t('page.policy.p_7'),
									}}
								></span>
							</H2>

							<RootDesc>
								{t('page.policy.d_7') !== '' && (
									<span
										dangerouslySetInnerHTML={{
											__html: t('page.policy.d_7'),
										}}
									></span>
								)}

								<ul>
									<li
										dangerouslySetInnerHTML={{
											__html: t('page.policy.l_7_1'),
										}}
									></li>
									<li
										dangerouslySetInnerHTML={{
											__html: t('page.policy.l_7_2'),
										}}
									></li>
									<li
										dangerouslySetInnerHTML={{
											__html: t('page.policy.l_7_3'),
										}}
									></li>
									<li
										dangerouslySetInnerHTML={{
											__html: t('page.policy.l_7_4'),
										}}
									></li>
									<li
										dangerouslySetInnerHTML={{
											__html: t('page.policy.l_7_5'),
										}}
									></li>
									{t('page.policy.l_7_6') !== '' && (
										<li
											dangerouslySetInnerHTML={{
												__html: t('page.policy.l_7_6'),
											}}
										></li>
									)}
								</ul>

								<span
									dangerouslySetInnerHTML={{
										__html: t('page.policy.d_7_1'),
									}}
								></span>
							</RootDesc>

							{t('page.policy.p_8') !== '' && (
								<>
									<H2>
										<span
											dangerouslySetInnerHTML={{
												__html: t('page.policy.p_8'),
											}}
										></span>
									</H2>

									<RootDesc>
										<span
											dangerouslySetInnerHTML={{
												__html: t('page.policy.d_8'),
											}}
										></span>
									</RootDesc>
								</>
							)}

							<H2>
								<span
									dangerouslySetInnerHTML={{
										__html: t('page.policy.p_9'),
									}}
								></span>
							</H2>

							<RootDesc>
								<span
									dangerouslySetInnerHTML={{
										__html: t('page.policy.d_9'),
									}}
								></span>
							</RootDesc>

							<H2>
								<span
									dangerouslySetInnerHTML={{
										__html: t('page.policy.p_10'),
									}}
								></span>
							</H2>

							<RootDesc>
								<span
									dangerouslySetInnerHTML={{
										__html: t('page.policy.d_10'),
									}}
								></span>
							</RootDesc>

							<H2>
								<span
									dangerouslySetInnerHTML={{
										__html: t('page.policy.p_11'),
									}}
								></span>
							</H2>

							<RootDesc>
								<span
									dangerouslySetInnerHTML={{
										__html: t('page.policy.d_11'),
									}}
								></span>
							</RootDesc>
						</div>
					</BorderArticle>
				</div>
			</div>

			<Footer />
		</>
	)
}
