import React, { useEffect, useState } from 'react'
import { Route, Routes } from 'react-router-dom'

import { Footer } from '@/components/layouts/Footer'
import { RootHeader } from '@/components/layouts/RootHeader'
import { Overview } from './Overview'
import { Features } from './Features'
import { Cases } from './Cases'
import { Dissection } from './Dissection'
import { Suit } from './Suit'
import { Login } from './Login'
import { ScrollBtn } from './components/ScrollBtn'
import { useSelector } from 'react-redux'

export const Home = () => {
	const [isScrollBtnVisible, setScrollBtnVisible] = useState(false)
	const { isMobile } = useSelector(state => state.settings)

	const scrollToTop = () => {
		window.scrollTo({ top: 0, behavior: 'smooth' })
	}

	useEffect(() => {
		const handleScroll = () => {
			if (window.scrollY > 950) {
				setScrollBtnVisible(true)
			} else {
				setScrollBtnVisible(false)
			}
		}

		window.addEventListener('scroll', handleScroll)

		return () => {
			window.removeEventListener('scroll', handleScroll)
		}
	}, [])

	return (
		<>
			<RootHeader />

			<Routes>
				<Route path='overview' element={<Overview />} />
				<Route path='features/*' element={<Features />} />
				<Route path='cases' element={<Cases />} />
				<Route path='dissection' element={<Dissection />} />
				<Route path='suit' element={<Suit />} />
				<Route path='login' element={<Login />} />
			</Routes>

			{isScrollBtnVisible && <ScrollBtn click={scrollToTop} />}

			<Footer />
		</>
	)
}
