import React from 'react'
import { Helmet } from 'react-helmet'

import { Intro } from './Intro'
import { Try } from '../components/Try'
import { SimpleSlider } from '../components/SimpleSlider'
import { usePopup } from '@/components/layouts/InfoPopup/PopupContext'
import { PresentationPopup } from '@/popups/requestPopups/PresentationPopup'

import eco from '@/assets/images/home/cases/eco.png'
import service from '@/assets/images/home/cases/service.png'
import inclusive from '@/assets/images/home/cases/inclusive.png'
import leadership from '@/assets/images/home/cases/leadership.png'
import crisis from '@/assets/images/home/cases/crisis.png'
import regulatory from '@/assets/images/home/cases/regulatory.png'
import collaboration from '@/assets/images/home/cases/collaboration.png'
import cultural from '@/assets/images/home/cases/cultural.png'

import quote_top from '@/assets/images/home/cases/quote-top.png'
import quote_bottom from '@/assets/images/home/cases/quote-bottom.png'

import styles from './styles.module.scss'
import { useSelector } from 'react-redux'

const ScenarioSlide = props => {
	const { title, image, color } = props

	return (
		<div className={styles.scenario_slide} style={{ background: `${color}` }}>
			<img src={image} alt='image-bg' />

			<h2>{title}</h2>
		</div>
	)
}

const FeedbackSlide = props => {
	const { title, subtitle, user_name, user_job } = props

	return (
		<div className={styles.feedback_slide}>
			<img src={quote_top} alt='quote' />
			<img src={quote_bottom} alt='quote' />

			<div className={styles.feedback_slide_content}>
				<h2>{title}</h2>

				<p>{subtitle}</p>
			</div>

			<div className={styles.feedback_slide_user}>
				<div>
					<span>
						<b>{user_name}</b>
					</span>
					<span>{user_job}</span>
				</div>
			</div>
		</div>
	)
}

export const Cases = () => {
	const { isMobile } = useSelector(state => state.settings)

	const scenariosItems = [
		{
			id: 0,
			block: (
				<ScenarioSlide
					title={
						<>
							Eco-Feedback <br /> Mastery
						</>
					}
					image={eco}
					color={
						'linear-gradient(109deg, rgba(87, 57, 150, 0.50) 0%, rgba(245, 129, 213, 0.50) 100%)'
					}
				/>
			),
		},
		{
			id: 1,
			block: (
				<ScenarioSlide
					title={
						<>
							Service Calls &<br /> Resolving Client <br /> Issues
						</>
					}
					image={service}
					color={
						'linear-gradient(90deg, #FFD293 0%, #FB9675 100%), rgba(255, 255, 255, 0.20)'
					}
				/>
			),
		},
		{
			id: 2,
			block: (
				<ScenarioSlide
					title={<>Inclusivity Training</>}
					image={inclusive}
					color={
						'linear-gradient(90deg, #FBC2EB 0%, #A6C1EE 100%), rgba(255, 255, 255, 0.20)'
					}
				/>
			),
		},
		{
			id: 3,
			block: (
				<ScenarioSlide
					title={
						<>
							Leadership <br /> Development
						</>
					}
					image={leadership}
					color={
						'linear-gradient(90deg, #D756E3 0%, #87E8FA 100%), rgba(255, 255, 255, 0.20)'
					}
				/>
			),
		},
		{
			id: 4,
			block: (
				<ScenarioSlide
					title={
						<>
							Crisis <br /> Communication <br /> Management
						</>
					}
					image={crisis}
					color={
						'linear-gradient(64deg, #7F60BE 14.62%, #FC83DA 84.71%), rgba(255, 255, 255, 0.20)'
					}
				/>
			),
		},
		{
			id: 5,
			block: (
				<ScenarioSlide
					title={
						<>
							Regulatory <br /> Compliance Training
						</>
					}
					image={regulatory}
					color={
						'linear-gradient(90deg, #FE974E 0%, #FEF4BA 100%), rgba(255, 255, 255, 0.20)'
					}
				/>
			),
		},
		{
			id: 6,
			block: (
				<ScenarioSlide
					title={
						<>
							Cross-functional <br /> Team Collaboration
						</>
					}
					image={collaboration}
					color={
						'linear-gradient(90deg, #FB6267 0%, #7A9EF6 100%), rgba(255, 255, 255, 0.20)'
					}
				/>
			),
		},
		{
			id: 7,
			block: (
				<ScenarioSlide
					title={
						<>
							Cultural Sensitivity <br /> and Global <br /> Communication
						</>
					}
					image={cultural}
					color={
						'linear-gradient(90deg, #A040A9 0%, #59D4EA 100%), rgba(255, 255, 255, 0.20)'
					}
				/>
			),
		},
	]

	const feedbackItems = [
		{
			id: 0,
			block: (
				<FeedbackSlide
					title={<>Faster Ramp Time and Improved Quota Attainment</>}
					subtitle={
						<>
							“Since implementing AVA, our new reps have cut ramp time by over
							40%, reaching quota-capable status in less than 7 months instead
							of the typical 11.2 months. The training library and role-play
							scenarios are invaluable, helping reps gain confidence and skills
							from day one. The team’s project management in implementation
							exceeded our expectations.”
						</>
					}
					user_name={<>Rachel M.</>}
					user_job={<>Sales Manager</>}
				/>
			),
		},
		{
			id: 1,
			block: (
				<FeedbackSlide
					title={<>Enhanced Coaching and Manager Efficiency</>}
					subtitle={
						<>
							“With AVA’s real-time performance insights, our managers now spend
							less time on one-to-one observations which has been a huge cost
							saving. AVA's data-driven approach has allowed us to deliver more
							targeted, effective coaching, improving rep performance across the
							board. We were a little skeptical at first, we soon recognised we
							were in trusted hands with Vivanti’s expertise.”
						</>
					}
					user_name={<>David P.</>}
					user_job={<>Sales Director, Global</>}
				/>
			),
		},
		{
			id: 2,
			block: (
				<FeedbackSlide
					title={<>Bridging the Gap Between Sales and Marketing</>}
					subtitle={
						<>
							“We went with Vivanti as we had previously worked with them on
							various digital marketing campaigns. AVA has helped us align our
							sales reps with the latest marketing strategies, ensuring that
							product messaging is consistent and impactful – they are now an
							integral part of our marketing strategy. Reps now feel more
							prepared and confident when engaging with healthcare professionals
							when relaying complex information - We’ve seen an increase in
							sales.”
						</>
					}
					user_name={<>Sarah T.</>}
					user_job={<>Marketing Director, EMEA</>}
				/>
			),
		},
		{
			id: 3,
			block: (
				<FeedbackSlide
					title={<>Continuous Learning for Success</>}
					subtitle={
						<>
							“We struggled with measuring results from one-off training
							sessions, AVA offers continuous, adaptive learning that evolves
							with our reps’ needs. This ongoing feedback loop ensures they are
							always on the cutting edge, better prepared for each sales visit,
							and ultimately more successful in meeting their targets.”
						</>
					}
					user_name={<>Jamie L.</>}
					user_job={<>Regional Sales Manager</>}
				/>
			),
		},
		{
			id: 5,
			block: (
				<FeedbackSlide
					title={<>AVA Made My Job Easier</>}
					subtitle={
						<>
							“At first, I was a bit worried that AVA might take over my role as
							a training coach. I thought it would make one-on-one coaching and
							in person trainings unnecessary. It actually made my job a lot
							easier and more focused. Instead of wasting time on generic
							repetitive training, I’m now focusing my time on giving
							personalised coaching using the insights AVA provided. The
							real-time feedback means I can really target the areas where my
							team needs help, speeding up their development and helping them
							hit their targets quicker.”
						</>
					}
					user_name={<>John W.</>}
					user_job={<>Training Coach; Pharmaceutical Sales</>}
				/>
			),
		},
		{
			id: 6,
			block: (
				<FeedbackSlide
					title={
						<>How AVA Helped Me Sharpen My Skills and Boost My Performance</>
					}
					subtitle={
						<>
							“At first, our team grumbled a bit about AVA, thinking it was just
							another additional task. But after using it for a while, we
							realised how helpful it actually was. The roleplay scenarios and
							real-time feedback allowed me to practise with real-life
							situations, helping me improve my approach with HCPs. It wasn’t
							just about reading through materials; AVA gave me the chance to
							adjust on the spot, which made me more confident in my
							interactions. It also helped me handle objections better and
							deliver key messages more effectively. I feel more prepared now,
							and my performance has definitely improved. AVA has genuinely made
							a difference.”
						</>
					}
					user_name={<>Sarah L.</>}
					user_job={<>Oncology Sales Representative</>}
				/>
			),
		},
	]

	const { openPopup } = usePopup()

	const handleOpenPopup = () => {
		openPopup(<PresentationPopup />)
	}

	return (
		<>
			<Helmet title='Cosmart AVA AI Trainer Use Cases' />

			<div className='container-big'>
				<div className={styles.cases_page}>
					<Intro />

					<div className='container-home'>
						<div className={styles.additional}>
							<h3>Additional Scenarios</h3>

							<SimpleSlider
								loop={true}
								cards={scenariosItems}
								between={!isMobile ? 0 : 16}
								perview={!isMobile ? 3 : 8}
								autoplay={1300}
								navigation={false}
								pagination={true}
								style={'additional'}
							/>
						</div>
					</div>

					<div className='container-home'>
						<Try
							size={35}
							title={<b>Transform your team's potential with AVA.</b>}
							button={<>Choose or create your unique training journey today!</>}
							onClickBtn={handleOpenPopup}
						/>
					</div>

					<div className={styles.feedback}>
						<SimpleSlider
							loop={false}
							cards={feedbackItems}
							between={!isMobile ? 0 : 32}
							perview={!isMobile ? 2 : 2}
							navigation={true}
							pagination={true}
							style={'feedback'}
						/>
					</div>
				</div>
			</div>
		</>
	)
}
