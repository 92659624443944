import React from 'react'
import moment from 'moment/min/moment-with-locales'
import { useLocation, useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import {
	createScenarioSession,
	createProductSession,
	setProductSessionId,
	setSessionType,
	setScenarioSessionId,
	setScore,
} from '@/redux/slices/avatarSlices/avatarSlice'

import { RootDesc } from '@/components/ui/descriptions/RootDesc'
import { Spinner } from '../Spinner'
import { BorderArticle } from '../BorderArticle'

import crownImage from '@/assets/images/cards/crown.svg'
import errorImage from '@/assets/images/popups/error-upload.svg'
import styles from './styles.module.scss'
import { useTranslation } from 'react-i18next'
import { useLevelOrder } from '@/hooks/LevelOrder'

export const PreviewCard = props => {
	const { levels } = useLevelOrder()
	const { name, image, due_date, mandatory, mask, levelList, id } = props
	const { serverStatus } = useSelector(state => state.avatar)
	const { demo, role } = useSelector(state => state.candidate)
	const { language } = useSelector(state => state.settings)
	const location = useLocation()
	const navigate = useNavigate()
	const dispatch = useDispatch()
	const { t } = useTranslation()

	const isDueDateApproaching = () => {
		if (due_date) {
			const now = moment()
			const dueDate = moment(due_date)
			const diffDays = dueDate.diff(now, 'days')

			return diffDays <= 14
		}
		return false
	}

	const handleClickLevel = async (item, id) => {
		if (demo && role === 'Representative') {
			if (location.pathname.includes('product')) {
				dispatch(setSessionType('product'))
				dispatch(setScenarioSessionId(null))
				dispatch(setScore(null))
				navigate(`/chat-game/${item.value}/${id}`, {
					state: { level: item },
				})
			} else {
				dispatch(setSessionType('scenario'))
				dispatch(setProductSessionId(null))
				dispatch(setScore(null))
				navigate(`/chat-game/${item.value}/${id}`, {
					state: { level: item },
				})
			}
		} else {
			if (location.pathname.includes('product')) {
				const resultAction = await dispatch(
					createProductSession({ level: item.value, product_id: id })
				)
				if (createProductSession.fulfilled.match(resultAction)) {
					dispatch(setSessionType('product'))
					dispatch(setScenarioSessionId(null))
					dispatch(setScore(null))
					navigate(`/chat-game/${item.value}/${id}`, {
						state: { level: item },
					})
				} else {
					console.log(t('session_error.create_session'))
				}
			} else {
				const resultAction = await dispatch(
					createScenarioSession({ level: item.value, scenario_id: id })
				)

				if (createScenarioSession.fulfilled.match(resultAction)) {
					dispatch(setSessionType('scenario'))
					dispatch(setProductSessionId(null))
					dispatch(setScore(null))
					navigate(`/chat-game/${item.value}/${id}`, {
						state: { level: item },
					})
				} else {
					console.log(t('session_error.create_session'))
				}
			}
		}
	}

	return (
		<BorderArticle>
			<div className={styles.card}>
				{serverStatus === 'loading' && <Spinner width={40} height={40} />}

				{image && (
					<img className={styles.cardPreview} src={image} alt='preview-image' />
				)}

				{mask && (
					<div
						className={styles.maskPreview}
						style={{ background: `var(--${mask})` }}
					></div>
				)}

				{(due_date || mandatory) && isDueDateApproaching() && (
					<div className={styles.cardHead}>
						{mandatory && <img src={errorImage} alt='warning' />}

						{due_date && (
							<RootDesc>
								<span>
									{t('preview_card.date')}{' '}
									{language !== 'ru' ? (
										<b>{moment(due_date).format('MMMM, DD')}</b>
									) : (
										<b>{moment(due_date).format('DD MMMM')}</b>
									)}
								</span>
							</RootDesc>
						)}
					</div>
				)}

				{name && <h2>{name}</h2>}

				{levelList && (
					<ul className={styles.cardFooter}>
						{levels.map(item => (
							<li onClick={() => handleClickLevel(item, id)} key={item?.id}>
								<RootDesc>
									<span>
										<b>{item?.name}</b>
									</span>
								</RootDesc>
							</li>
						))}
					</ul>
				)}
			</div>
		</BorderArticle>
	)
}
