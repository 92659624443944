import React from 'react'
import styles from './styles.module.scss'
import { RootButton } from '@/components/ui/buttons/RootButton'
import { useSelector } from 'react-redux'

export const Try = ({ title, onClickBtn, button, size = 44 }) => {
	const { isMobile } = useSelector(state => state.settings)

	return (
		<div className={styles.try}>
			<h4
				style={!isMobile ? { fontSize: `${size}rem` } : { fontSize: '28rem' }}
			>
				{title}
			</h4>

			<RootButton txt={button || 'Let’s Try'} onClick={onClickBtn} />
		</div>
	)
}
