import React, { useState } from 'react'
import ReactPlayer from 'react-player/youtube'

import { Logo } from '@/components/ui/general/Logo'

import videoBtn from '@/assets/images/home/video-btn.png'
import styles from './styles.module.scss'

export const Video = props => {
	const { videoUrl, image, title } = props

	const [isPlaying, setIsPlaying] = useState(false)
	const [videoError, setVideoError] = useState(false)

	const handleClickPlay = () => {
		setIsPlaying(true)
	}

	const handleVideoEnded = () => {
		setIsPlaying(false)
	}

	const handleVideoError = () => {
		setVideoError(true)
	}

	return (
		<div className={styles.video_wrapper}>
			{!videoError && videoUrl && isPlaying ? (
				<ReactPlayer
					playing={true}
					url={videoUrl}
					width={'100%'}
					height={'100%'}
					onEnded={handleVideoEnded}
					onError={handleVideoError}
					fallback={<img src={image} alt='video-image' />}
				/>
			) : (
				<div className={styles.video_preview}>
					<div className={styles.video_left}>
						<h2>{title}</h2>

						<Logo direction={'footer'} />
					</div>

					<div onClick={handleClickPlay} className={styles.video_btn}>
						<img src={videoBtn} alt='video-button' />
					</div>

					<div className={styles.video_right}>
						<i></i>
						<img src={image} alt='video-image' />
					</div>
				</div>
			)}
		</div>
	)
}
