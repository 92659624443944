import i18n from 'i18next'
import Backend from 'i18next-http-backend'
import { initReactI18next } from 'react-i18next'
import enTranslation from '@/assets/locales/en/translation.json'
import ruTranslation from '@/assets/locales/ru/translation.json'
// import LanguageDetector from 'i18next-browser-languagedetector'

const language = document
	.querySelector('[data-ava-lang]')
	.getAttribute('data-ava-lang')

i18n
	.use(Backend)
	.use(initReactI18next)
	// .use(LanguageDetector) // с помощью плагина определяем язык пользователя в браузере
	.init({
		debug: false,
		detection: {
			order: ['queryString', 'cookie'],
			cache: ['cookie'],
		},
		fallbackLng: language || 'en',
		interpolation: {
			escapeValue: false,
		},
		resources: {
			en: {
				translation: enTranslation,
			},
			ru: {
				translation: ruTranslation,
			},
		},
	})

export default i18n
