import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import { useInView } from 'react-intersection-observer'

import { RootDesc } from '@/components/ui/descriptions/RootDesc'
import { BorderArticle } from '@/components/ui/general/BorderArticle'

import blob from '@/assets/images/home/blue-blob.png'
import stars from '@/assets/images/home/features/stars.svg'
import check from '@/assets/images/home/features/check.svg'
import plus from '@/assets/images/home/features/plus.svg'
import minus from '@/assets/images/home/features/minus.svg'

import styles from './styles.module.scss'

const AccordeonItem = ({ item }) => {
	const [open, setOpen] = useState(false)
	const { isMobile } = useSelector(state => state.settings)

	const { ref, inView, entry } = useInView({
		threshold: 1,
	})

	const handleClickItem = () => {
		if (item.body) setOpen(!open)
	}

	return (
		<div
			onClick={handleClickItem}
			className={styles.accordeon_item}
			ref={ref}
			style={!isMobile && inView ? { opacity: '1' } : {}}
		>
			<div className={styles.accordeon_item_desc}>
				<div className={styles.accordeon_item_head}>
					<h3>{item.head}</h3>

					{item.body && <img src={open ? minus : plus} alt='icon' />}
				</div>

				{open && (
					<div className={styles.accordeon_item_body}>
						<RootDesc>
							<p>{item.body}</p>
						</RootDesc>
					</div>
				)}
			</div>

			{!isMobile && (
				<>
					<div className={styles.accordeon_item_check}>
						{item.check_1 && <img src={check} alt='check' />}
					</div>

					<div className={styles.accordeon_item_check}>
						{item.check_2 && <img src={check} alt='check' />}
					</div>
				</>
			)}
		</div>
	)
}

export const Accordeon = props => {
	const { title, tableHead, items, subtitle } = props
	const { isMobile } = useSelector(state => state.settings)
	const [showAdvanced, setShowAdvanced] = useState(false)

	const standardItems = items.filter(
		item => item.check_1 === true && item.check_2 === true
	)
	const advancedItems = items.filter(
		item => !(item.check_1 === true && item.check_2 === true)
	)

	const handleCheckboxChange = () => {
		setShowAdvanced(!showAdvanced)
	}

	return (
		<div className={styles.accordeon}>
			<img className={styles.blob} src={blob} alt='blob' />

			<div className={styles.accordeon_title}>
				<h2>{title}</h2>

				<img src={stars} alt='icon' />
			</div>

			{!isMobile ? (
				<div className={styles.accordeon_table}>
					<BorderArticle>
						<ul>
							<li>
								<div
									className={`${styles.accordeon_item} ${styles.accordeon_item_th}`}
								>
									{tableHead &&
										tableHead.length > 0 &&
										tableHead.map((head, index) => (
											<React.Fragment key={index}>
												<RootDesc>
													<b>{head}</b>
												</RootDesc>
											</React.Fragment>
										))}
								</div>
							</li>

							{items &&
								items.length > 0 &&
								items.map(item => (
									<li key={item.id}>
										<AccordeonItem item={item} />
									</li>
								))}
						</ul>
					</BorderArticle>

					<RootDesc>
						<span>{subtitle}</span>
					</RootDesc>
				</div>
			) : (
				<div className={styles.accordeon_table_mobile}>
					<BorderArticle>
						<div className={styles.accordeon_table_head}>
							<span>{tableHead[1]}</span>

							<BorderArticle>
								<label htmlFor='check-features'>
									<input
										id='check-features'
										type='checkbox'
										onChange={handleCheckboxChange}
									/>

									<i></i>
								</label>
							</BorderArticle>

							<span>{tableHead[2]}</span>
						</div>

						<ul className={styles.accordeon_table_body}>
							<li>
								<div
									className={`${styles.accordeon_item} ${styles.accordeon_item_th}`}
								>
									<RootDesc>
										<b>{tableHead[0]}</b>
									</RootDesc>
								</div>
							</li>

							{(showAdvanced
								? [...standardItems, ...advancedItems]
								: standardItems
							).map(item => (
								<li key={item.id}>
									<AccordeonItem item={item} />
								</li>
							))}
						</ul>
					</BorderArticle>

					<RootDesc>
						<span>{subtitle}</span>
					</RootDesc>
				</div>
			)}
		</div>
	)
}
