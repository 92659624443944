import React from 'react'

import { BenefitsHoverCard } from './BenefitsHoverCard'

import styles from './styles.module.scss'

export const Benefits = props => {
	const { items, itemBackColor, itemChild, itemHover } = props

	return (
		<div
			className={styles.benefits}
			style={{
				'--itemChild': itemChild,
				'--itemBackColor': itemBackColor,
				'--itemHover': itemHover,
			}}
		>
			{items &&
				items.length > 0 &&
				items.map((item, index) => (
					<React.Fragment key={item.id}>
						<BenefitsHoverCard
							item={item}
							index={index}
							itemChild={itemChild}
						/>
					</React.Fragment>
				))}
		</div>
	)
}
