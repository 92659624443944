import React from 'react'
import { useSelector } from 'react-redux'

import { capitalize } from '@/helpers/helpers'
import { RootDesc } from '@/components/ui/descriptions/RootDesc'
import { H3 } from '@/components/ui/titles/H3'
import { RootButton } from '@/components/ui/buttons/RootButton'
import { RootIcon } from '@/components/ui/icons/RootIcon'

import styles from './styles.module.scss'
import { useTranslation } from 'react-i18next'

export const LevelPopup = props => {
	const { t } = useTranslation()
	const { item, close } = props
	const { serverStatus, sessionType } = useSelector(state => state.avatar)
	const { demo, role } = useSelector(state => state.candidate)

	const descriptionLevelList = [
		{
			id: 0,
			text:
				sessionType === 'scenario'
					? t('popup.simulation.easy_scenario')
					: t('popup.simulation.easy_product'),
		},
		{
			id: 1,
			text:
				sessionType === 'scenario'
					? t('popup.simulation.medium_scenario')
					: t('popup.simulation.medium_product'),
		},
		{
			id: 2,
			text:
				sessionType === 'scenario'
					? t('popup.simulation.hard_scenario')
					: t('popup.simulation.hard_product'),
		},
	]

	if (!item) return null

	return (
		<div className={styles.levelPopup}>
			<h2>
				{capitalize(item?.name)} {t('table.level')}
			</h2>

			<H3>
				{sessionType === 'scenario'
					? t('popup.simulation.title_scenario')
					: t('popup.simulation.title_product')}
			</H3>

			<RootDesc>
				<span className='block text-center'>
					{descriptionLevelList[item?.id].text}
				</span>
			</RootDesc>

			{serverStatus === 'loading' ? (
				<div className={styles.spinner}>
					<RootIcon width={26} height={26} id={'spinner-upload'} />
				</div>
			) : (
				<RootButton
					txt={
						demo && role === 'Representative'
							? t('button.start_chat')
							: t('button.continue')
					}
					type={'button'}
					onClick={close}
				/>
			)}
		</div>
	)
}
