import { useEffect, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
	getScenarioSessions,
	setPage,
	setTotalPages,
	setSize,
	setSort,
	getProductSessions,
} from '@/redux/slices/sessionSlices/sessionsSlice'
import { useTranslation } from 'react-i18next'
import moment from 'moment/min/moment-with-locales'

import { useLevelOrder } from '@/hooks/LevelOrder'
import { useScoreValue } from '@/hooks/ScoreValue'
import { ScreenLayout } from '@/components/layouts/ScreenLayout'
import TableLayout from '@/components/layouts/TableLayout'
import { RootCheckbox } from '@/components/ui/inputs/RootCheckbox'
import { TableButton } from '@/components/ui/buttons/TableButton'
import { useLocation } from 'react-router-dom'

export const UserAnalytics = () => {
	const currentScore = useScoreValue()
	const { currentLevel } = useLevelOrder()
	const { t } = useTranslation()
	const dispatch = useDispatch()
	const location = useLocation()

	const { sessions, page, size, sort, totalPages, serverStatus, error } =
		useSelector(state => state.sessions)

	const goToNextPage = () => {
		if (page < totalPages) {
			dispatch(setPage(page + 1))
		} else return
	}

	const goToPreviousPage = () => {
		if (page > 1) {
			dispatch(setPage(page - 1))
		} else return
	}

	const goToPage = pageIndex => {
		dispatch(setPage(pageIndex + 1))
	}

	const handleSelectSize = option => {
		dispatch(setSize(+option))
		dispatch(setPage(1))
		dispatch(setTotalPages(Math.ceil(totalPages / size)))
	}

	const handleSelectSort = option => {
		dispatch(setSort(option))
	}

	const scenarioColumns = useMemo(
		() => [
			{
				id: 'selection',
				Header: ({ getToggleAllRowsSelectedProps }) => (
					<div>
						<RootCheckbox {...getToggleAllRowsSelectedProps()} />
					</div>
				),
				Cell: ({ row }) => (
					<div>
						<RootCheckbox {...row.getToggleRowSelectedProps()} />
					</div>
				),
				width: 50,
			},
			{ Header: t('table.scenario'), accessor: 'scenario', width: 255 },
			{ Header: t('table.user'), accessor: 'user', width: 220 },
			{
				Header: t('table.products'),
				accessor: 'scenario_products',
				Cell: ({ cell: { value } }) => {
					return <>{value && value.join(', ')}</>
				},
				width: 220,
			},
			{
				Header: t('table.level'),
				accessor: 'level',
				Cell: ({ cell: { value } }) => {
					return <>{currentLevel(value)}</>
				},
				width: 160,
			},
			{
				Header: t('table.status'),
				accessor: 'score',
				Cell: ({ cell: { value } }) => {
					return <>{currentScore(value).grade}</>
				},
				width: 195,
			},
			{
				Header: t('table.created'),
				accessor: 'created_at',
				Cell: ({ cell: { value } }) => {
					return <>{moment(value).format('DD-MM-YYYY')}</>
				},
				width: 210,
			},
			{
				Header: t('table.actions'),
				Cell: ({ row }) => (
					<div className='flex gap-[8rem] phone:gap-[40rem]'>
						<TableButton
							icon='view'
							color='var(--orange)'
							label={t('table.button.view')}
							action={'view'}
							item={row.original}
						/>

						<TableButton
							icon='delete'
							color='var(--red)'
							label={t('table.button.delete')}
							action={'delete'}
							item={row.original}
						/>
					</div>
				),
				width: 155,
			},
		],
		[]
	)

	const productColumns = useMemo(
		() => [
			{
				id: 'selection',
				Header: ({ getToggleAllRowsSelectedProps }) => (
					<div>
						<RootCheckbox {...getToggleAllRowsSelectedProps()} />
					</div>
				),
				Cell: ({ row }) => (
					<div>
						<RootCheckbox {...row.getToggleRowSelectedProps()} />
					</div>
				),
				width: 50,
			},
			{ Header: t('table.product'), accessor: 'product', width: 190 },
			{ Header: t('table.user'), accessor: 'user', width: 240 },
			{ Header: t('table.product_line'), accessor: 'product_line', width: 270 },
			{
				Header: t('table.level'),
				accessor: 'level',
				Cell: ({ cell: { value } }) => {
					return <>{currentLevel(value)}</>
				},
				width: 155,
			},
			{
				Header: t('table.score'),
				accessor: 'score',
				Cell: ({ row }) => {
					const score = row?.original?.score
					const maxScore = row?.original?.questions_count

					return <>{`${score} / ${maxScore}`}</>
				},
				width: 190,
			},
			{
				Header: t('table.created'),
				accessor: 'created_at',
				Cell: ({ cell: { value } }) => {
					return <>{moment(value).format('DD-MM-YYYY')}</>
				},
				width: 215,
			},
			{
				Header: t('table.actions'),
				Cell: ({ row }) => (
					<div className='flex gap-[8rem] phone:gap-[40rem]'>
						<TableButton
							icon='view'
							color='var(--orange)'
							label={t('table.button.view')}
							action={'view'}
							item={row.original}
						/>

						<TableButton
							icon='delete'
							color='var(--red)'
							label={t('table.button.delete')}
							action={'delete'}
							item={row.original}
						/>
					</div>
				),
				width: 155,
			},
		],
		[]
	)

	useEffect(() => {
		if (Object.keys(sort).length !== 0) {
			location.pathname.includes('scenario')
				? dispatch(getScenarioSessions({ page, size }))
				: dispatch(getProductSessions({ page, size }))
		}
	}, [page, size, sort, location])

	useEffect(() => {
		const firstAccessor = location.pathname.includes('scenario')
			? scenarioColumns.find(column => column.accessor)
			: productColumns.find(column => column.accessor)

		dispatch(
			setSort({ name: firstAccessor.Header, value: firstAccessor.accessor })
		)
	}, [])

	return (
		<ScreenLayout switchView={true} title={t('page.analytics.title')}>
			<TableLayout
				serverStatus={serverStatus}
				serverError={error}
				nextPage={goToNextPage}
				previousPage={goToPreviousPage}
				totalPages={totalPages}
				dataSize={size}
				currentPage={page}
				dataSort={sort}
				selectSort={handleSelectSort}
				toPage={goToPage}
				selectSize={handleSelectSize}
				columns={
					location.pathname.includes('scenario')
						? scenarioColumns
						: productColumns
				}
				data={sessions}
			/>
		</ScreenLayout>
	)
}
