import React from 'react'

import { HeroVideo } from '../components/HeroVideo'
import { HeroTitle } from '../components/HeroTitle'
import { HeroBlock } from '../components/HeroBlock'

import comprehensive from '@/assets/images/home/features/comprehensive.svg'
import integrating from '@/assets/images/home/features/integrating.svg'
import language from '@/assets/images/home/features/language.svg'
import processing from '@/assets/images/home/features/processing.svg'
import implementation from '@/assets/images/home/features/implementation.svg'
import hotline from '@/assets/images/home/features/hotline.svg'
import comprehensive_hover from '@/assets/images/home/features/comprehensive-hover.svg'
import integrating_hover from '@/assets/images/home/features/integrating-hover.svg'
import language_hover from '@/assets/images/home/features/language-hover.svg'
import processing_hover from '@/assets/images/home/features/processing-hover.svg'
import implementation_hover from '@/assets/images/home/features/implementation-hover.svg'
import hotline_hover from '@/assets/images/home/features/hotline-hover.svg'

import styles from './styles.module.scss'

export const Intro = () => {
	const cardList = [
		{
			id: 0,
			icon: comprehensive,
			iconHover: comprehensive_hover,
			subtitle: (
				<>
					Comprehensive process <br />
					analysis and implementation recommendations
				</>
			),
		},
		{
			id: 1,
			icon: integrating,
			iconHover: integrating_hover,
			subtitle: (
				<>
					Integrating user accounts <br />
					and structure
				</>
			),
		},
		{
			id: 2,
			icon: language,
			iconHover: language_hover,
			subtitle: (
				<>
					Language and cultural <br />
					preferences setup
				</>
			),
		},
		{
			id: 3,
			icon: processing,
			iconHover: processing_hover,
			subtitle: (
				<>
					Data processing and initial <br />
					setup of&nbsp;up&nbsp;to&nbsp;30 scenarios <br />
					across different product lines
				</>
			),
		},
		{
			id: 4,
			icon: implementation,
			iconHover: implementation_hover,
			subtitle: (
				<>
					Training for trainers <br />
					and managers to&nbsp;support <br />
					implementation
				</>
			),
		},
		{
			id: 5,
			icon: hotline,
			iconHover: hotline_hover,
			subtitle: <>Hotline for user support</>,
		},
	]

	return (
		<div className={styles.intro}>
			<HeroVideo top={'-550'} />

			<HeroTitle>
				<span>
					<strong>AVA</strong> AI Trainer
				</span>

				<span>Features</span>
			</HeroTitle>

			<div className='container-home'>
				<HeroBlock
					cardsTitle={<>Initial setup includes:</>}
					cards={cardList}
					itemHover={'linear-gradient(135deg, #8692CB 0%, #B262C9 100%)'}
					description={
						<span>
							In&nbsp;collaboration with pharma employees and HCPs, we&nbsp;
							<nobr>co-developed</nobr> a dedicated solution to&nbsp;address the
							strong demand for personalised learning, which showed
							a&nbsp;significant impact when tested <br /> in&nbsp;real-case
							scenarios.
							<br />
							<br />
							With our standard package, you gain immediate access
							to&nbsp;a&nbsp;cloud server as&nbsp;soon as&nbsp;the project
							begins, setting the foundation for efficient and streamlined
							operations.
						</span>
					}
				/>
			</div>
		</div>
	)
}
