import React from 'react'
import styles from './styles.module.scss'
import { Intro } from './Intro'
import { Benefits } from './Benefits'
import { Video } from './Video'
import trainersBlob from '@/assets/images/home/peach-blob.png'
import repBlob from '@/assets/images/home/blue-blob.png'
import videoImage from '@/assets/images/home/video-image.png'
import { Apart } from './Apart'
import { Helmet } from 'react-helmet'

export const Overview = () => {
	const managers = [
		{
			id: 0,
			title: (
				<>
					Benefits <br />
					for Managers
				</>
			),
			subtitle: (
				<>
					Improve your sales team performance and drive higher revenues. AVA
					AI&nbsp;Trainer enables managers to&nbsp;track results, identify
					trends, and analyse individual performance.
				</>
			),
			icon: null,
		},
		{
			id: 1,
			title: (
				<>
					Consistency <br />
					and Alignment <br />
					with Marketing <br />
					Strategy
				</>
			),
			subtitle: (
				<>
					Keep training consistent and in&nbsp;line with marketing goals for
					a&nbsp;single standardised approach and compliance with the marketing
					strategy.
				</>
			),
			icon: 'consistency',
		},
		{
			id: 2,
			title: (
				<>
					Flexibility and <br />
					Adaptation to&nbsp;
					<br />
					Changes
				</>
			),
			subtitle: (
				<>
					Quickly update training to&nbsp;meet new rules or&nbsp;market changes,
					with tailored scenarios for team tasks or&nbsp;HCP objections.
				</>
			),
			icon: 'flexibility',
		},
		{
			id: 3,
			title: (
				<>
					Progress <br /> Monitoring
				</>
			),
			subtitle: (
				<>
					Make results clear and easily accessible for reps, managers, and
					trainers, ensuring everyone stays informed.
				</>
			),
			icon: 'progress',
		},
		{
			id: 4,
			title: (
				<>
					Sales Data <br /> Correlation
				</>
			),
			subtitle: (
				<>
					Compare simulation analytics with field data to&nbsp;identify problem
					areas and improve efficiency.
				</>
			),
			icon: 'sales_data',
		},
		{
			id: 5,
			title: (
				<>
					Targeted <br /> Training Focus
				</>
			),
			subtitle: (
				<>
					Pinpoint underperforming areas or&nbsp;skill gaps from simulation data
					and tailor bridging exercises to&nbsp;address specific needs
					effectively.
				</>
			),
			icon: 'targeted',
		},
	]

	const trainers = [
		{
			id: 0,
			title: (
				<>
					Balanced <br /> Learning
				</>
			),
			subtitle: (
				<>
					Combines AVA’s&nbsp;scalable training with <nobr>expert-led</nobr>,
					customised sessions.
				</>
			),
			icon: 'balanced',
		},
		{
			id: 1,
			title: (
				<>
					Benefits for <br /> Trainers
				</>
			),
			subtitle: (
				<>
					Synergy Between AVA and <nobr>Expert-Led</nobr> Training. AVA
					doesn’t&nbsp;replace trainers; it&nbsp;acts as&nbsp;a&nbsp;powerful
					partner, enhancing their ability to&nbsp;oversee targeted and scalable
					training from theory to&nbsp;practice.
				</>
			),
			icon: null,
		},
		{
			id: 2,
			title: (
				<>
					Data-Driven <br /> Feedback
				</>
			),
			subtitle: (
				<>
					AVA&rsquo;s logs equip trainers with detailed insights into group
					dynamics and individual needs, enabling them to&nbsp;tailor their
					sessions more effectively and address specific challenges within the
					team.
				</>
			),
			icon: 'data_driven',
		},
		{
			id: 3,
			title: (
				<>
					Continuous <br /> Learning Loop
				</>
			),
			subtitle: (
				<>
					Reps build core skills through AVA, with live sessions adding depth
					based on&nbsp;real performance and needs.
				</>
			),
			icon: 'continuous',
		},
		{
			id: 4,
			title: (
				<>
					Scalable <br /> Training
				</>
			),
			subtitle: (
				<>
					Deliver consistent, <nobr>high-quality</nobr> training to&nbsp;large
					teams across regions.
				</>
			),
			icon: 'scalable',
		},
		{
			id: 5,
			title: (
				<>
					Reduce Time <br /> while <br /> Increasing <br /> Personalisation
				</>
			),
			subtitle: (
				<>
					AVA streamlines routine training tasks, freeing up&nbsp;trainers
					to&nbsp;focus on&nbsp;delivering tailored coaching that addresses
					individual team member needs.
				</>
			),
			icon: 'reduce_time',
		},
	]

	const representatives = [
		{
			id: 0,
			title: <>24/7 Access</>,
			subtitle: (
				<>
					Continuously improve by&nbsp;learning and practising key sales skills
					and product knowledge at&nbsp;their own pace.
				</>
			),
			icon: 'access',
		},
		{
			id: 1,
			title: (
				<>
					Realistic <br /> Practice
				</>
			),
			subtitle: (
				<>
					Engage in&nbsp;<nobr>AI-driven</nobr> simulations to&nbsp;hone their
					approach without the pressure of&nbsp;live interactions.
				</>
			),
			icon: 'practice',
		},
		{
			id: 2,
			title: (
				<>
					Benefits for <br /> Representatives
				</>
			),
			subtitle: (
				<>
					AVA supports sales reps at&nbsp;every career stage, offering
					a&nbsp;safe space for both newcomers and experienced professionals
					to&nbsp;learn, practice, and enhance their HCP engagement skills.
				</>
			),
			icon: null,
		},
		{
			id: 3,
			title: (
				<>
					Customisable <br /> Scenarios
				</>
			),
			subtitle: (
				<>
					Tailored training scenarios allow reps to&nbsp;practice
					in&nbsp;situations that are directly relevant to&nbsp;their specific
					field, making the training highly applicable and effective.
				</>
			),
			icon: 'customisable',
		},
		{
			id: 4,
			title: (
				<>
					Enhanced HCP <br /> Engagement
				</>
			),
			subtitle: (
				<>
					Perfect the art of&nbsp;communication to&nbsp;ensure more impactful
					and meaningful interactions with HCPs.
				</>
			),
			icon: 'hcp',
		},
		{
			id: 5,
			title: (
				<>
					Personalised <br /> Feedback
				</>
			),
			subtitle: (
				<>
					Immediate, actionable feedback on&nbsp;communication techniques and
					product knowledge empowers reps to&nbsp;refine their approach and
					improve their persuasive skills.
				</>
			),
			icon: 'feedback',
		},
	]

	return (
		<>
			<Helmet title='Cosmart AVA AI Trainer Overview' />

			<div className='container-big'>
				<div className={styles.overview_page}>
					<Intro />

					<div className='container-home'>
						<Benefits
							items={managers}
							itemChild={0}
							itemBackColor={
								'linear-gradient(109deg, rgba(87, 57, 150, 0.50) 0%, rgba(245, 129, 213, 0.50) 100%), rgba(255, 255, 255, 0.20)'
							}
							itemHover={'linear-gradient(135deg, #8692CB 0%, #B262C9 100%)'}
						/>
					</div>

					<div className='container-home'>
						<Benefits
							items={trainers}
							itemChild={1}
							itemBackColor={
								'linear-gradient(90deg, rgba(255, 154, 158, 0.80) 0%, rgba(250, 208, 196, 0.80) 99%, rgba(250, 208, 196, 0.80) 100%), rgba(255, 255, 255, 0.20)'
							}
							itemHover={'linear-gradient(90deg, #FFD293 0%, #FB9675 100%)'}
						/>

						<img
							className={styles.trainers_blob}
							src={trainersBlob}
							alt='blob'
						/>
					</div>

					<div className='container-home'>
						<Benefits
							items={representatives}
							itemChild={2}
							itemBackColor={
								'linear-gradient(90deg, #A6C0FE 0%, #F68084 100%), rgba(255, 255, 255, 0.20)'
							}
							itemHover={'linear-gradient(90deg, #FBC2EB 0%, #A6C1EE 100%)'}
						/>

						<img
							className={styles.representatives_blob}
							src={repBlob}
							alt='blob'
						/>
					</div>

					<Video
						image={videoImage}
						videoUrl={'https://youtu.be/Gvca6wunpns'}
						title={
							<>
								<strong>01</strong>
								<b>Introducing AVA</b>
								<span>First Steps</span>
							</>
						}
					/>

					<div className='container-home'>
						<Apart />
					</div>
				</div>
			</div>
		</>
	)
}
