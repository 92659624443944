import React from 'react'

import { BorderArticle } from '@/components/ui/general/BorderArticle'
import { ApartHoverCard } from './ApartHoverCard'
import { Try } from '../components/Try'
import { usePopup } from '@/components/layouts/InfoPopup/PopupContext'
import { PresentationPopup } from '@/popups/requestPopups/PresentationPopup'

import styles from './styles.module.scss'

export const Apart = () => {
	const list = [
		{
			id: 0,
			title: (
				<>
					Tailored for Pharma <br /> Challenges
				</>
			),
			subtitle: (
				<>
					Built for the complexities of&nbsp;pharmaceutical sales, AVA bridges
					Sales and Marketing, ensuring reps are equipped to&nbsp;deliver
					aligned messaging that resonates with HCPs.
				</>
			),
			icon: 'tailored',
		},
		{
			id: 1,
			title: <>More Than a Classic LMS</>,
			subtitle: (
				<>
					Unlike LMS systems, AVA provides immersive, interactive simulations
					with <nobr>AI-generated</nobr> feedback, ensuring sales reps actively
					practice and refine skills, not just passively consume content.
				</>
			),
			icon: 'lms',
		},
		{
			id: 2,
			title: (
				<>
					Amplifies, Doesn’t <br /> Replace Human Trainers
				</>
			),
			subtitle: (
				<>
					AVA acts as&nbsp;a&nbsp;powerful trainer partner, allowing trainers
					to&nbsp;focus on&nbsp;<nobr>high-value</nobr> coaching while the
					platform handles scalable, consistent, and repeatable training.
				</>
			),
			icon: 'amplifies',
		},
		{
			id: 3,
			title: (
				<>
					Competence Validation <br /> Through Practice
				</>
			),
			subtitle: (
				<>
					AVA uses repeatable scenarios to&nbsp;reinforce learning, validate
					skills, and ensure teams are prepared for <nobr>real-world</nobr>{' '}
					challenges.
				</>
			),
			icon: 'competence',
		},
	]

	const { openPopup } = usePopup()

	const handleOpenPopup = () => {
		openPopup(<PresentationPopup />)
	}

	return (
		<BorderArticle>
			<div className={styles.apart_wrap}>
				<div className={styles.apart_desc}>
					<h3>What Sets AVA Apart?</h3>

					<p>
						AVA Simulation Trainer isn’t&nbsp;just another training
						tool&nbsp;&mdash; it’s&nbsp;a&nbsp;dynamic platform that complements
						human <br />
						trainers and traditional LMS systems by&nbsp;focusing
						on&nbsp;scalable, personalised learning and measurable impact.
					</p>

					<span>Here’s how AVA stands out:</span>
				</div>

				<ul
					className={styles.apart_list}
					style={{
						'--itemHover': 'linear-gradient(90deg, #B622C3 0%, #2DDEFD 100%)',
					}}
				>
					{list &&
						list.length > 0 &&
						list.map((item, index) => (
							<React.Fragment key={item.id}>
								<ApartHoverCard item={item} index={index} />
							</React.Fragment>
						))}
				</ul>

				<Try
					onClickBtn={handleOpenPopup}
					title={
						<b>
							AVA is&nbsp;a&nbsp;<nobr>purpose-built</nobr> platform for sales
							force transformation <br />
							and excellence.
						</b>
					}
				/>
			</div>
		</BorderArticle>
	)
}
