import React from 'react'
import styles from './styles.module.scss'
import { BorderArticle } from '@/components/ui/general/BorderArticle'
import { RootDesc } from '@/components/ui/descriptions/RootDesc'
import { H3 } from '@/components/ui/titles/H3'

const CardItem = ({ icon, iconHover, subtitle, title }) => {
	return (
		<BorderArticle>
			<div className={styles.hero_card}>
				<i></i>

				<div className={styles.hero_card_icons}>
					<img src={icon} alt='icon' />
					<img src={iconHover} alt='icon-hover' />
				</div>

				<div className={styles.hero_card_content}>
					{title && <h4>{title}</h4>}

					<RootDesc>
						<span>{subtitle}</span>
					</RootDesc>
				</div>
			</div>
		</BorderArticle>
	)
}

export const HeroBlock = props => {
	const { description, cardsTitle, itemHover, cards } = props

	return (
		<div className={styles.hero_block}>
			<div className={styles.hero_desc}>{description}</div>

			<div
				className={styles.hero_cards}
				style={{
					'--itemHover': itemHover,
				}}
			>
				{cardsTitle && (
					<div className={styles.hero_subtitle}>
						<H3>
							<span>{cardsTitle}</span>
						</H3>
					</div>
				)}

				<ul>
					{cards &&
						cards.length > 0 &&
						cards.map(card => (
							<li key={card.id}>
								<CardItem
									icon={card.icon}
									iconHover={card.iconHover}
									subtitle={card.subtitle}
									title={card.title}
								/>
							</li>
						))}
				</ul>
			</div>
		</div>
	)
}
