import React, { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'
import { v4 as uuidv4 } from 'uuid'
import { useTranslation } from 'react-i18next'

import {
	setMessages,
	setUserMessage,
	sendMessage,
	setIsRecording,
	getQuestion,
	sendAnswer,
	getRecordToken,
	setBotMessage,
	setIsSessionActive,
} from '@/redux/slices/avatarSlices/avatarSlice'

import { BorderArticle } from '@/components//ui/general/BorderArticle'
import { RootDesc } from '@/components/ui/descriptions/RootDesc'
import { RootIcon } from '@/components/ui/icons/RootIcon'
import { Scrollbar } from '@/components/ui/general/Scrollbar'

import recImage from '@/assets/images/chat/rec-message.svg'
import sendMessageImage from '@/assets/images/chat/sending-message.svg'

import styles from './styles.module.scss'
import axios from 'axios'

export const Chat = ({ time }) => {
	const dispatch = useDispatch()
	const location = useLocation()

	const textareaRef = useRef(null)

	const {
		questions,
		scenario_session_id,
		product_session_id,
		answer_id,
		messages,
		userMessage,
		serverStatus,
		isRecording,
		isSessionActive,
		recordToken,
		recordHost,
	} = useSelector(state => state.avatar)

	const { language } = useSelector(state => state.settings)

	const [mediaRecorder, setMediaRecorder] = useState(null)
	const [audioChunks, setAudioChunks] = useState([])
	const [audioFile, setAudioFile] = useState(null)
	const [transcript, setTranscript] = useState('')
	const [loadingTranscript, setLoadingTranscript] = useState(false)
	const [textareaHeight, setTextareaHeight] = useState('24rem')
	const { t } = useTranslation()

	const handleChangeMessage = e => {
		setTextareaHeight('auto')
		setTextareaHeight(`${textareaRef.current.clientHeight}rem`)
		dispatch(setUserMessage(e.target.value))
	}

	const handleSendMessage = async e => {
		e.preventDefault()

		if (userMessage.trim() && userMessage.length !== 0) {
			const user = {
				messageId: uuidv4(),
				sender: 'user',
				text: userMessage,
			}

			dispatch(setMessages(user))

			if (product_session_id) {
				const resultAction = await dispatch(
					sendAnswer({ id: product_session_id, answer_id, answer: user.text })
				)

				if (sendAnswer.fulfilled.match(resultAction)) {
					if (questions > 0) {
						dispatch(getQuestion({ id: product_session_id }))
					}

					dispatch(setUserMessage(''))
					stopRecording()

					if (transcript !== '') {
						setTranscript('')
					}
				} else {
					console.log('error: send answer')
				}
			} else {
				const resultAction = await dispatch(
					sendMessage({ id: scenario_session_id, msg: user.text })
				)

				if (sendMessage.fulfilled.match(resultAction)) {
					dispatch(setUserMessage(''))
					stopRecording()

					if (transcript !== '') {
						setTranscript('')
					}
				} else {
					console.log('error: send message')
				}
			}
		}
	}

	const startRecording = async () => {
		try {
			dispatch(getRecordToken())
			dispatch(setIsRecording(true))

			const stream = await navigator.mediaDevices.getUserMedia({ audio: true })
			const recorder = new MediaRecorder(stream)

			setMediaRecorder(recorder)

			const chunks = []

			recorder.ondataavailable = e => {
				chunks.push(e.data)
			}

			recorder.onstop = async () => {
				const audioBlob = new Blob(chunks, { type: 'audio/wav' })
				const audioFile = new File([audioBlob], 'audio_file_ava.wav', {
					type: 'audio/wav',
				})

				setAudioFile(audioFile)
				setAudioChunks([])
			}

			recorder.start()
		} catch (err) {
			console.error('Error accessing microphone:', err)
		}
	}

	const stopRecording = () => {
		if (mediaRecorder && mediaRecorder.state !== 'inactive') {
			mediaRecorder.stop()
			mediaRecorder.stream.getTracks().forEach(track => track.stop())

			dispatch(setIsRecording(false))
		}
	}

	const fetchAudio = async (host, file, token) => {
		if (file.size >= 24 * 1024 * 1024) {
			return console.log('Размер файла превышает 24 МБ!')
		}

		// "en", "ru", "de", "es", "fr" - поддерживаемые языки
		// https://speech-to-text.ml.vivanti.eu/transcribe

		const browserLanguage = navigator.language || navigator.userLanguage
		const supportedLanguages = ['en', 'ru', 'de', 'es', 'fr']
		const browserLanguageCode = browserLanguage.split('-')[0]

		let languageCode = language

		if (supportedLanguages.includes(browserLanguageCode)) {
			languageCode = browserLanguageCode
		}

		setLoadingTranscript(true)

		const formData = new FormData()

		formData.append('language', languageCode)
		formData.append('audiofile', file)

		const response = await axios.post(`${host}/transcribe`, formData, {
			headers: {
				Authorization: `Bearer ${token}`,
				'Content-Type': 'multipart/form-data',
			},
		})

		setTranscript(prev => prev + ` ${response?.data?.text}` || '')
	}

	const handleKeyDown = e => {
		if (e.key === 'Enter' && !e.shiftKey) {
			e.preventDefault()
			handleSendMessage(e)
		}
	}

	useEffect(() => {
		if (transcript !== '') {
			dispatch(setUserMessage(transcript))
			setLoadingTranscript(false)
		}
	}, [transcript])

	useEffect(() => {
		if (
			audioFile &&
			recordHost &&
			recordToken &&
			mediaRecorder &&
			mediaRecorder.state === 'inactive'
		) {
			fetchAudio(recordHost, audioFile, recordToken)
		}
	}, [audioFile])

	useEffect(() => {
		if (scenario_session_id && messages.length > 0 && time === 10000) {
			const text = t('page.chat.final_bot_message')

			const finalMessage = {
				messageId: uuidv4(),
				text,
				sender: 'bot',
			}

			dispatch(setIsSessionActive(false))
			dispatch(setBotMessage(text))
			dispatch(setMessages(finalMessage))
		}
	}, [time, scenario_session_id])

	useEffect(() => {
		setTextareaHeight(
			userMessage ? `${textareaRef.current.scrollHeight}rem` : '24rem'
		)
	}, [userMessage])

	useEffect(() => {
		if (!isSessionActive) {
			stopRecording()
		}
	}, [isSessionActive])

	useEffect(() => {
		return () => {
			stopRecording()
		}
	}, [location])

	useEffect(() => {
		const chat = document.querySelector('.rcs-inner-container')
		if (!chat) return

		chat.scrollTop = chat.scrollHeight

		if (textareaRef.current) {
			textareaRef.current.focus()
		}
	}, [messages])

	return (
		<BorderArticle>
			<div className={styles.gameChat}>
				<Scrollbar>
					<div className={styles.chat}>
						{messages &&
							messages.length > 0 &&
							messages.map((msg, index) => (
								<div
									key={index}
									className={
										msg.sender === 'bot'
											? styles.botMessage
											: styles.userMessage
									}
								>
									<RootDesc>
										{msg.sender === 'bot' ? (
											<b>{msg.text}</b>
										) : (
											<span>{msg.text}</span>
										)}
									</RootDesc>

									{msg.sender !== 'bot' && (
										<img
											className={styles.userMessageIcon}
											src={sendMessageImage}
											alt='sending'
										/>
									)}
								</div>
							))}
					</div>
				</Scrollbar>

				<div
					className={
						isSessionActive
							? styles.chatControls
							: styles.chatControls + ' ' + styles.chatControlsDisabled
					}
				>
					<div className={styles.textarea}>
						<textarea
							disabled={serverStatus === 'loading' ? true : false}
							ref={textareaRef}
							type={'text'}
							value={userMessage}
							onChange={handleChangeMessage}
							onKeyDown={handleKeyDown}
							style={{
								height: textareaHeight,
								maxHeight: '140rem',
								overflowY: 'scroll',
							}}
						/>
					</div>

					<div className={styles.chatControlsBtns}>
						{serverStatus === 'loading' || loadingTranscript ? (
							<div className={styles.spinner} style={{ pointerEvents: 'none' }}>
								<RootIcon width={26} height={26} id={'spinner-upload'} />
							</div>
						) : (
							<div
								onClick={handleSendMessage}
								style={
									isRecording
										? {
												opacity: '0.5',
												pointerEvents: 'none',
												animation: 'none',
										  }
										: { opacity: '1', pointerEvents: 'all', animation: 'none' }
								}
								className={styles.spinner}
							>
								<RootIcon width={26} height={26} id={'send-message'} />
							</div>
						)}

						<div
							className={
								!isRecording ? styles.rec : styles.rec + ' ' + styles.recActive
							}
							onClick={
								!isRecording && !loadingTranscript
									? startRecording
									: stopRecording
							}
						>
							<button
								disabled={
									serverStatus === 'loading' || loadingTranscript ? true : false
								}
								type='button'
							>
								<img src={recImage} alt='rec' />
							</button>
						</div>
					</div>
				</div>
			</div>
		</BorderArticle>
	)
}
