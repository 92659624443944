import React from 'react'
import { useSelector } from 'react-redux'
import { useInView } from 'react-intersection-observer'

import { BorderArticle } from '@/components/ui/general/BorderArticle'
import { RootIcon } from '@/components/ui/icons/RootIcon'

import styles from './styles.module.scss'

export const ApartHoverCard = ({ item }) => {
	const { isMobile } = useSelector(state => state.settings)

	const { ref, inView, entry } = useInView({
		threshold: 0.5,
	})

	return (
		<div
			key={item.id}
			className={styles.apart_item}
			ref={ref}
			style={inView ? { opacity: '1' } : {}}
		>
			<BorderArticle>
				<div>
					<i></i>
					<RootIcon
						width={isMobile ? 32 : 64}
						height={isMobile ? 32 : 64}
						id={item.icon}
					/>
					<h3>{item.title}</h3>
					<span>{item.subtitle}</span>
				</div>
			</BorderArticle>
		</div>
	)
}
