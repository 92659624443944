import React, { useEffect, useRef } from 'react'
import { useLocation, useParams } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import Markdown from 'markdown-to-jsx'
import {
	clearViewSession,
	viewScenarioSession,
	viewProductSession,
} from '@/redux/slices/sessionSlices/viewSessionSlice'
import generatePDF, { Resolution, Margin } from 'react-to-pdf'
import moment from 'moment/min/moment-with-locales'

import { BorderArticle } from '@/components//ui/general/BorderArticle'
import { RootDesc } from '@/components/ui/descriptions/RootDesc'
import { BackButton } from '@/components/ui/buttons/BackButton'
import { Spinner } from '@/components//ui/general/Spinner'
import { Scrollbar } from '@/components/ui/general/Scrollbar'
import { ControlButton } from '@/components/ui/buttons/ControlButton'
import { useLevelOrder } from '@/hooks/LevelOrder'
import { useScoreValue } from '@/hooks/ScoreValue'

import styles from './styles.module.scss'
import { useTranslation } from 'react-i18next'

const SessionField = ({ label, value }) => {
	return (
		<li>
			<RootDesc>
				<b className='block w-[250rem]'>{label}</b>
			</RootDesc>

			<div className={styles.sessionField}>
				<i></i>
				<RootDesc>
					<span>{value}</span>
				</RootDesc>
			</div>
		</li>
	)
}

export const SessionViewLayout = () => {
	const dispatch = useDispatch()
	const location = useLocation()
	const { t } = useTranslation()
	const id = useParams().id

	const { currentLevel } = useLevelOrder()
	const currentScore = useScoreValue()
	const { isMobile } = useSelector(state => state.settings)
	const { session, serverStatus } = useSelector(state => state.viewSession)
	const sessionRef = useRef()

	const sessionName = location.pathname.includes('scenario')
		? session?.scenario
		: session?.product

	const pdfOptions = {
		filename: `${sessionName ? sessionName.replace(/ /g, '_') : ''}_${moment(
			session?.created_at
		).format(`DD_MM_YYYY`)}.pdf`,
		method: 'save',
		resolution: Resolution.MEDIUM, // 3
		page: {
			margin: Margin.MEDIUM, // 10мм
			format: 'A4',
			orientation: 'portrait',
		},
	}

	const getTargetElement = () => document.getElementById('pdf-content')

	useEffect(() => {
		location.pathname.includes('scenario') &&
			dispatch(viewScenarioSession({ id }))
		location.pathname.includes('product') &&
			dispatch(viewProductSession({ id }))

		return () => {
			dispatch(clearViewSession())
		}
	}, [dispatch])

	useEffect(() => {
		if (sessionRef.current && isMobile) {
			sessionRef.current.scrollIntoView({ behavior: 'smooth' })
		}
	}, [location])

	return (
		<div ref={sessionRef} className={styles.sessionWrapper}>
			<BorderArticle>
				<div className={styles.session}>
					<BackButton />

					{serverStatus === 'loading' ? (
						<Spinner width={80} height={80} />
					) : (
						<>
							<div className={styles.sessionGenerateBtn}>
								<ControlButton
									color='var(--green)'
									txt={t('button.generate_pdf')}
									onClick={() => generatePDF(getTargetElement, pdfOptions)}
								/>
							</div>

							<div id='pdf-content' className={styles.sessionContent}>
								<div className={styles.sessionContentHead}>
									<h2>
										{moment(session?.created_at).format(`DD-MM-YYYY`)}{' '}
										{moment(session?.created_at).format(`HH:mm:ss`)}
									</h2>
								</div>

								<ul className={styles.sessionContentBody}>
									{location.pathname.includes('scenario') ? (
										<SessionField
											label={t('table.scenario')}
											value={session?.scenario}
										/>
									) : (
										<SessionField
											label={t('table.product')}
											value={session?.product}
										/>
									)}

									<SessionField label={t('table.user')} value={session?.user} />

									{location.pathname.includes('scenario') ? (
										<SessionField
											label={t('table.products')}
											value={
												session?.scenario_products &&
												session?.scenario_products.join(', ')
											}
										/>
									) : (
										<SessionField
											label={t('table.product_line')}
											value={session?.product_line}
										/>
									)}

									<SessionField
										label={t('table.level')}
										value={currentLevel(session?.level)}
									/>

									{location.pathname.includes('scenario') ? (
										<SessionField
											label={t('table.status')}
											value={currentScore(session?.score).grade}
										/>
									) : (
										<SessionField
											label={t('table.score')}
											value={`${session?.score} / ${session?.questions_count}`}
										/>
									)}

									<SessionField
										label={t('page.session.field.feedback')}
										value={
											<Scrollbar bottom={false}>
												<div className={styles.sessionMarkdown}>
													{session?.feedback && (
														<Markdown>{session?.feedback}</Markdown>
													)}
												</div>
											</Scrollbar>
										}
									/>

									<li className='relative'>
										<RootDesc>
											<b className='block w-[250rem]'>
												{t('page.session.field.dialog')}
											</b>
										</RootDesc>

										{location.pathname.includes('scenario') ? (
											<Scrollbar bottom={false}>
												<div className={styles.sessionMessages}>
													{session?.messages &&
														session?.messages.map(message => (
															<div
																className={styles.sessionMessage}
																key={message?.id}
															>
																<RootDesc>
																	<div className='flex flex-col'>
																		<span>
																			<span className='underline'>
																				{t('page.session.dialog.rep')}
																			</span>{' '}
																			{message?.request}
																		</span>
																		<span>
																			<span className='underline'>
																				{t('page.session.dialog.doc')}
																			</span>{' '}
																			{message?.response}
																		</span>
																	</div>
																</RootDesc>
															</div>
														))}
												</div>
											</Scrollbar>
										) : (
											<Scrollbar>
												<div className={styles.sessionMessages}>
													{session?.answers &&
														session?.answers.map(message => (
															<div
																className={styles.sessionMessage}
																key={message?.id}
															>
																<RootDesc>
																	<div className='flex flex-col'>
																		<span>
																			<span className='underline'>
																				{t('page.session.dialog.doc')}
																			</span>{' '}
																			{message?.question}
																		</span>
																		<span>
																			<span className='underline'>
																				{t('page.session.dialog.rep')}
																			</span>{' '}
																			{message?.answer}
																		</span>
																	</div>
																</RootDesc>
															</div>
														))}
												</div>
											</Scrollbar>
										)}
									</li>
								</ul>
							</div>
						</>
					)}
				</div>
			</BorderArticle>
		</div>
	)
}
