import React from 'react'
import { useInView } from 'react-intersection-observer'

import hcp from '@/assets/images/home/suit/hcp-back.png'

import styles from './styles.module.scss'

const HcpCard = ({ title, subTitle }) => {
	const { ref, inView, entry } = useInView({
		threshold: 1,
	})

	return (
		<article
			className={styles.hcp_card}
			ref={ref}
			style={inView ? { opacity: '1' } : {}}
		>
			<i></i>

			<h4>{title}</h4>

			<span>{subTitle}</span>
		</article>
	)
}

export const Hcp = () => {
	const hcpCards = [
		{
			id: 0,
			title: <>CLM Suite</>,
			subTitle: (
				<>
					Personalised information in CLM <br /> through dynamically adaptive
					content.
				</>
			),
		},
		{
			id: 1,
			title: <>CLM Suite + AI Guide</>,
			subTitle: (
				<>
					Interactive assistant during calls <br /> for quick access to
					essential <br /> information.
				</>
			),
		},
		{
			id: 2,
			title: <>CLM Suite + RepCoPilot</>,
			subTitle: (
				<>
					Comprehensive call data collection,
					<br /> including HCP interests, questions,
					<br /> and objections.
				</>
			),
		},
		{
			id: 3,
			title: <>RepCoPilot + AVA AI Mentor</>,
			subTitle: (
				<>
					Post-call automated feedback <br /> for representatives on their
					knowledge <br /> and skills.
				</>
			),
		},
		{
			id: 4,
			title: <>NINA — Predictive Analytics Platform</>,
			subTitle: (
				<>
					Recommendations for future <br /> communications with HCPs, including
					<br /> preferred channels and content types.
				</>
			),
		},
		{
			id: 5,
			title: <>AVA AI Trainer</>,
			subTitle: (
				<>
					Interactive training assistant <br /> to enhance the skills of medical
					<br /> representatives.
				</>
			),
		},
		{
			id: 6,
			title: <>NBA — Next Best Action</>,
			subTitle: (
				<>
					Automated follow-ups providing <br /> additional information tailored
					<br /> to the doctor's interests.
				</>
			),
		},
	]

	return (
		<div className={styles.hcp_wrapper}>
			<h3>The Future of HCPs Interactions</h3>

			<div className={styles.hcp_cards}>
				{hcpCards &&
					hcpCards.length > 0 &&
					hcpCards.map(card => (
						<React.Fragment key={card.id}>
							<HcpCard title={card.title} subTitle={card.subTitle} />
						</React.Fragment>
					))}
			</div>

			<div className={styles.hcp_back}>
				<img src={hcp} alt='hcp' />
			</div>

			<div className={styles.hcp_bottom}>
				<p>
					Vivanti <br /> is an Innovative and Impactful <br /> Marketing
					and Efficiency Solutions <br /> Company for Life Sciences.
				</p>
			</div>
		</div>
	)
}
