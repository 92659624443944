import { Route, Routes } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { useMemo } from 'react'

import { ProfileStatisticLayout } from '@/components/layouts/ProfileStatisticLayout'
import { Header } from '@/components/layouts/Header'
import { SideBar } from '@/components/layouts/SideBar'
import { UserAnalytics } from '@/screens/user/UserAnalytics'
import { SessionViewLayout } from '@/components/layouts/SessionLayout/SessionViewLayout'
import { Footer } from '@/components/layouts/Footer'

import styles from './styles.module.scss'
import { useTranslation } from 'react-i18next'

export const UserPanel = () => {
	const { t } = useTranslation()
	const { id } = useSelector(state => state.candidate)

	const userBarList = [
		{
			id: 0,
			iconId: 'profile',
			name: t('sidebar.profile'),
			link: `profile/${id}`,
		},
		{
			id: 1,
			iconId: 'analytics',
			name: t('sidebar.history'),
			link: 'analytics/scenario',
		},
	]

	return (
		<div className={styles.userPanel}>
			<Header />

			<div className='container-big'>
				<div className={styles.main}>
					<SideBar list={userBarList} />

					<Routes>
						<Route path='profile/:id' element={<ProfileStatisticLayout />} />
						<Route path='analytics/scenario/*' element={<UserAnalytics />} />
						<Route
							path='analytics/scenario/view/:id'
							element={<SessionViewLayout />}
						/>
						<Route path='analytics/product/*' element={<UserAnalytics />} />
						<Route
							path='analytics/product/view/:id'
							element={<SessionViewLayout />}
						/>
					</Routes>
				</div>
			</div>

			<Footer />
		</div>
	)
}
