import React from 'react'
import { Intro } from './Intro'
import { Helmet } from 'react-helmet'

export const Dissection = () => {
	return (
		<>
			<Helmet title='Cosmart AVA AI Trainer Amalytics' />

			<div className='container-big'>
				<Intro />
			</div>
		</>
	)
}
