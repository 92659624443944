import React from 'react'
import styles from './styles.module.scss'

import bg from '@/assets/images/home/intro-bg.png'
import fallbackImage from '@/assets/images/home/fallback-video.png'
import heroImage from '@/assets/images/home/mobile-hero-bg.png'
import { useSelector } from 'react-redux'

// раскоментировать для теста видео
// import videoMp4 from '@/assets/video-hero.mp4'
// import videoMov from '@/assets/video-hero.mov'
// import videoWebm from '@/assets/video-hero.webm'

export const HeroVideo = ({ hero = false, top = '-260' }) => {
	// закоментировать для теста видео
	const videoMov = '/video/video-hero.mov'
	const videoWebm = '/video/video-hero.webm'
	const videoMp4 = '/video/video-hero.mp4'

	const { isMobile } = useSelector(state => state.settings)

	return (
		<div
			className={styles.intro_video}
			style={!isMobile ? { top: `${top}rem` } : { top: `-128rem` }}
		>
			{!isMobile ? (
				<div
					className={
						hero ? styles.intro_video_mask_hero : styles.intro_video_mask
					}
				>
					{videoMp4 && videoWebm && videoMov && (
						<video
							autoPlay={true}
							loop={true}
							muted={true}
							width={'100%'}
							height={'1080rem'}
							poster={fallbackImage}
						>
							<source src={videoMov} type='video/quicktime' />
							<source src={videoWebm} type='video/webm' />
							<source src={videoMp4} type='video/mp4' />
						</video>
					)}

					{/* <img className={styles.intro_bg_image} src={bg} alt='background' /> */}
				</div>
			) : (
				<div
					className={
						hero ? styles.intro_video_mask_hero : styles.intro_video_mask
					}
				>
					<img src={heroImage} alt='background' />

					{/* <img className={styles.intro_bg_image} src={bg} alt='background' /> */}
				</div>
			)}
		</div>
	)
}
